
export function generateUUID() {

    let dt = Date.now().toString(16)
    dt = Number("0x" + dt);
    function randomDig(c, dt) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    }

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => randomDig(c, dt))

    return uuid

}

// function generateUUID() {
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
//         var r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)

//         return v.toString(16)
//     })
// }

export function formatDateTime(date) {

    return `${formatDate(date)}T${formatTime(date)}`
}

export function formatDate(date) {

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export function formatTime(date) {

    const hours = String(date.getHours()).padStart(2, 0)
    const minutes = String(date.getMinutes()).padStart(2, 0)
    const seconds = String(date.getSeconds()).padStart(2, 0)

    return `${hours}:${minutes}:${seconds}`
}