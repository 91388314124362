
import dataCustomers from './customers.json'
import dataOrders from './orders.json'
import dataProducts from './products.json'
import dataUOMClassifier from './uom-classifier.json'

const dataList = (xtsTypeName) => {

    var dataList = []
    switch (xtsTypeName) {
        case 'XTSCounterparty':
            dataList = dataCustomers
            break
        case 'XTSOrder':
            dataList = dataOrders
            break
        case 'XTSProduct':
            dataList = dataProducts
            break
        case 'XTSUOMClassifier':
            dataList = dataUOMClassifier
            break
        default:
    }

    return dataList
}

export default dataList