/////////////////////////////////////////////
// Standard's

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/////////////////////////////////////////////
// Application's

import { postRequest, extraData, reducers, setLoading, setSucceeded, setError } from './slice-common'

/////////////////////////////////////////////
// Begin

const initialState = {
    items: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
    tempData: null,  // dữ liệu của các objects được lưu tạm thời, ví dụ khi được tải về bằng XTSGetObjectsRequest
}

export const apiRequest = createAsyncThunk(
    'customers/apiRequest',
    (data, thunkAPI) => postRequest(data, thunkAPI, sliceCustomers.actions)
)

const sliceCustomers = createSlice({
    name: 'customers',
    initialState,
    reducers,
    extraReducers(builder) {
        builder
            .addCase(apiRequest.pending, (state) => setLoading(state))
            .addCase(apiRequest.fulfilled, (state, action) => setSucceeded(state))
            .addCase(apiRequest.rejected, (state, action) => setError(state, action))

    },
    extra: extraData,
})

/////////////////////////////////////////////
// Export's

export default sliceCustomers.reducer

export const {
    add,
    update,
    remove,
    load,
    setStatus,
    setTemp
} = sliceCustomers.actions