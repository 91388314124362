

import AppMenu from './menu'

function AppSider() {

    return (
        <div>
            <AppMenu isInline={true} />
        </div>
    )
}

export default AppSider