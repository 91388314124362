/////////////////////////////////////////////
// Standard's

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/////////////////////////////////////////////
// Application's

import { postRequest, extraData, reducers, setLoading, setSucceeded, setError } from './slice-common'
import { xtsObjectToFormValues, xtsValueToFormValue } from '../data-exchange/common-use'
import { createXTSObject } from "../data-exchange/objects";

/////////////////////////////////////////////
// Begin

const initialState = {
    selectedValue: null,
    order: null,
    pageTitle: 'Ứng dụng bán hàng',
    tempData: null,  // dữ liệu của các objects được lưu tạm thời
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
}

const reducersOrder = {

    setValue(state, action) {
        state.selectedValue = action.payload
    },

    createOrder(state, action) {
        _createOrder(state.order, action.payload)
    },

    updateOrder(state, action) {
        _updateOrder(state.order, action.payload)
    },

    addOrderRow(state, action) {
        _addOrderRow(state.order, action.payload)
    },

    updateOrderRow(state, action) {
        _updateOrderRow(state.order, action.payload)
    },

    deleteOrderRow(state, action) {
        _deleteOrderRow(state.order, action.payload)
    },
    setPageTitle(state, action) {
        state.pageTitle = action.payload
    }

}

export const apiRequest = createAsyncThunk(
    'current/apiRequest',                                                           // current         
    (data, thunkAPI) => postRequest(data, thunkAPI, sliceCurrentData.actions)       // sliceCurrentData
)

const sliceCurrentData = createSlice({                                              // sliceCurrentData
    name: 'current',                                                                // current
    initialState,
    reducers: { ...reducers, ...reducersOrder },
    extraReducers(builder) {
        builder
            .addCase(apiRequest.pending, (state) => setLoading(state))
            .addCase(apiRequest.fulfilled, (state, action) => setSucceeded(state))
            .addCase(apiRequest.rejected, (state, action) => setError(state, action))

    },
    extra: extraData,
})

/////////////////////////////////////////////
// Internal

function _createOrder(order, xtsOrder) {
    order = createXTSObject('XTSOrder')
    if (xtsOrder) {
        _updateOrder(order, xtsOrder)
    }
}

function _updateOrder(order, xtsOrder) {
    if (order) {
        const xtsOrder = createXTSObject('XTSOrder')
        order = xtsObjectToFormValues(xtsOrder)
    }

    for (const key in xtsOrder) {
        const xtsValue = xtsOrder['key']
        const formValues = xtsValueToFormValue(xtsValue)
        for (const k in formValues) {
            order[k] = formValues[k]
        }
    }
}


function _addOrderRow(order, xtsOrderRow) {


}

function _updateOrderRow(order, xtsOrderRow) {


}

function _deleteOrderRow(order, xtsOrderRow) {
    const products = order.products
    const { xtsProduct, xtsUOM } = xtsOrderRow
    if (xtsUOM) {
        order.products = products.filter(row => (
            row.product.objectId.id === xtsProduct.objectId.id &
            row.uom.objectId.id === xtsUOM.objectId.id
        ))
    } else {
        order.products = products.filter(row => (
            row.product.objectId.id === xtsProduct.objectId.id
        ))
    }
}


/////////////////////////////////////////////
// Export's

export default sliceCurrentData.reducer                                                 // sliceCurrentData

export const {
    setValue,
    createOrder,
    updateOrder,
    addOrderRow,
    updateOrderRow,
    deleteOrderRow,
    setPageTitle,
} = sliceCurrentData.actions                                                            // sliceCurrentData