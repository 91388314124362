/////////////////////////////////////////////
// Standard's

import { Routes, Route, Outlet } from 'react-router-dom'
import { Layout } from 'antd'

/////////////////////////////////////////////
// Object's

import CheckAuth from '../hocs/auth-checking'
import { ParamsProvider } from '../hocs/params-provider'

import HomePage from '../pages/home'
import AboutPage from '../pages/about'
import LandingPage from '../pages/landing'
import LoginPage from '../pages/log-in'
import LogoutPage from '../pages/log-out'
import SupportPage from '../pages/support'
import CustomersPage from '../pages/customer'
// import CustomerListPage from '../pages/customer-list'
// import CustomerViewPage from '../pages/customer-view'
// import CustomerEditPage from '../pages/customer-edit'
import ProductsPage from '../pages/product'
// import ProductListPage from '../pages/product/product-list'
// import ProductPage from '../pages/product/product-view'
// import ProductEditPage from '../pages/product-edit'
// import OrderListPage from '../pages/order-list'
import OrdersPage from '../pages/order'
// import OrdersPage from '../pages/order'
// import OrderPage from '../pages/order-view'
// import OrderEditPage from '../pages/order-edit'
import ProductCharacteristicListPage from '../pages/product-characteristic-list'
import ProductCharacteristicPage from '../pages/product-characteristic-view'
import UOMClassifierListPage from '../pages/uom-classifier-list'

import UserProfilePage from '../pages/user-profile'
import SettingsPage from '../pages/settings'

import AppHeader from './header';
import AppFooter from './footer';
import AppSider from './sider';

import { HEADER_HEIGHT, FOOTER_HEIGHT, MENU_BACKGROUND_COLOR } from '../commons/constants'

/////////////////////////////////////////////
// Test

import TestPage from '../pages/test'        // Test


/////////////////////////////////////////////
// Main component

// function AppRoutes() {
//     return (
//         <div>
//             <ParamsProvider>
//                 <CheckAuth >
//                     <Routes>
//                         <Route path='/' element={<LandingPage />} />
//                         <Route path='/home' element={<HomePage />} />
//                         <Route path='/support' element={<SupportPage />} />
//                         <Route path='/about' element={<AboutPage />} />
//                         <Route path='/logout' element={<LogoutPage />} />
//                         <Route path='/customers' element={<CustomersPage />} />
//                         {/* <Route path='/customers' element={<CustomerListPage />} /> */}
//                         {/* <Route path='/customer/:id' element={<CustomerViewPage />} /> */}
//                         {/* <Route path='/customer/edit/:id' element={<CustomerEditPage />} /> */}
//                         {/* <Route path='/orders' element={<OrderListPage />} /> */}
//                         <Route path='/orders' element={<OrdersPage />} />
//                         {/* <Route path='/order/:id' element={<OrderViewPage />} /> */}
//                         {/* <Route path='/order/edit/:id' element={<OrderEditPage />} /> */}
//                         <Route path='/products' element={<ProductListPage />} />
//                         <Route path='/product/:id' element={<ProductPage />} />
//                         {/* <Route path='/product/edit/:id' element={<ProductEditPage />} /> */}
//                         <Route path='/product-characteristics' element={<ProductCharacteristicListPage />} />
//                         <Route path='/product-characteristic/:id' element={<ProductCharacteristicPage />} />
//                         <Route path='/uom-classifier' element={<UOMClassifierListPage />} />
//                         <Route path='/login' element={<LoginPage />} />

//                         <Route path='/user-profile' element={<UserProfilePage />} />
//                         <Route path='/settings' element={<SettingsPage />} />

//                         <Route path='/test' element={<TestPage />} />
//                     </Routes>
//                 </CheckAuth>
//             </ParamsProvider>
//         </div>
//     )
// }

export const RouteItems = () => {

    const { Header, Sider, Content, Footer } = Layout
    const element = (
        <div style={{ 'display': 'flex', height: '100vh', backgroundColor: 'rgb(0, 150, 255)' }}>
            <Sider
                trigger={null}
                breakpoint='sm'
                collapsedWidth='0'
                className='WebHeader'
                style={{
                    height: '100vh',
                    motionDurationMid: '0.5s',
                    backgroundColor: MENU_BACKGROUND_COLOR
                }} >
                <AppSider />
            </Sider >
            <Layout style={{ 'display': 'flex', height: '100vh' }}>
                <CheckAuth >
                    <Header style={{ height: HEADER_HEIGHT, padding: 0 }}>
                        <AppHeader />
                    </Header>
                    <Content style={{ overflowY: 'auto' }} >
                        <Outlet />
                    </Content>
                    <Footer style={{ height: FOOTER_HEIGHT, padding: 0 }}>
                        <AppFooter />
                    </Footer>
                </CheckAuth>
            </Layout>
        </div >
    )

    return [
        {
            path: '/',
            element,
            children: [
                { path: '/', element: < LandingPage /> },
                { path: '/home', element: < HomePage /> },
                { path: '/support', element: < SupportPage /> },
                { path: '/about', element: < AboutPage /> },
                { path: '/logout', element: < LogoutPage /> },
                { path: '/customers', element: < CustomersPage /> },
                // { path: '/customer/:id', element: < CustomerViewPage /> },
                // { path: '/customer/edit/:id', element: < CustomerEditPage /> },
                { path: '/orders', element: < OrdersPage /> },
                // { path: '/order/:id', element: < OrderPage /> },
                // { path: '/order/edit/:id', element: < OrderEditPage /> },
                { path: '/products', element: < ProductsPage /> },
                // { path: '/product/:id', element: < ProductPage /> },
                { path: '/product-characteristics', element: < ProductCharacteristicListPage /> },
                { path: '/product-characteristic/:id', element: < ProductCharacteristicPage /> },
                { path: '/uom-classifier', element: < UOMClassifierListPage /> },
                { path: '/login', element: < LoginPage /> },
                { path: '/user-profile', element: < UserProfilePage /> },
                { path: '/settings', element: < SettingsPage /> },
                { path: '/test', element: < TestPage /> },
            ]
        }
    ]
}

// export default AppRoutes