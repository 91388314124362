
/////////////////////////////////////////////
// Standard's

import { useLocation, Navigate, useSearchParams } from "react-router-dom";

/////////////////////////////////////////////
// Application's

import { useSessionParams } from "../hooks/use-params";

/////////////////////////////////////////////
// Main component


function CheckAuth({ children }) {

    const { params } = useSessionParams()
    const { pathname = '/', search } = useLocation()
    const path = pathname + search

    const skippingRoutes = [
        '/login',
        '/logout',
        '/about',
        '/products',
        '/product/',
        '/uom-classifier',
    ]
    // console.log("location:")
    // console.log(location)

    if (!params.userName & pathname === '/logout') {
        return <Navigate to='/' replace={true} />
        // } else if (!params.userName & fromPage != '/login' & fromPage != '/about' & fromPage != '/') {
    } else if (!params.userName & pathname !== '/' & skippingRoutes.findIndex(item => (pathname.startsWith(item))) === -1) {
        return <Navigate to='/login' state={{ path }} />
    } else {
        return children
    }
}

/////////////////////////////////////////////
// Export's

export default CheckAuth