
/////////////////////////////////////////////
// Main component

function UserProfilePage(props) {
    return (
        <div>
            <p>User profile</p>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default UserProfilePage