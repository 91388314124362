import { Form, Input, Button, Select } from 'antd'

import { useState } from 'react'
import { createXTSObject } from '../data-exchange/objects'

function TestPage() {

    // const [XTSObjectJSON, setXTSObjectJSON] = useState('Hi')
    const [form] = Form.useForm()

    const onChange = (value, form) => {
        console.log(value)
        // console.log(objectsDescription)
        // setXTSObjectJSON(value)
        const newObject = createXTSObject(value)
        const newObjectJSON = JSON.stringify(newObject, null, 2)
        // setXTSObjectJSON(newObjectJSON)
        console.log(newObjectJSON)
        form.setFieldValue("textArea", newObjectJSON)
    }

    const onFinish = (values => {
        const userName = values.userName
        const password = values.password
        console.log(userName)
    })

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const onClick = () => {

    }

    return (
        <div>
            <p>Test page</p>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                style={{ maxWidth: '80%' }}
            >
                <Form.Item label='Select type' >
                    <Select placeholder='Select XTS-type' options={Options} onChange={
                        (value) => { onChange(value, form) }
                    } name='xtsType' />
                </Form.Item>
                <Form.Item label='Kết quả' name='textArea'>
                    <Input.TextArea rows={18} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={onClick}>Load customer</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

const Options = [
    {
        value: "XTSObjectId",
        label: "XTSObjectId"
    }, {
        value: "XTSProduct",
        label: "XTSProduct"
    },
    {
        value: "XTSOrder",
        label: "XTSOrder"
    },
    {
        value: "XTSCounterparty",
        label: "XTSCounterparty"
    },
    {
        value: "XTSGender",
        label: "XTSGender"
    },
    {
        value: "XTSGetObjectListRequest",
        label: "XTSGetObjectListRequest"
    },
    {
        value: "XTSGetObjectsRequest",
        label: "XTSGetObjectsRequest"
    },
    {
        value: "XTSCreateObjectsRequest",
        label: "XTSCreateObjectsRequest"
    },
    {
        value: "XTSUpdateObjectsRequest",
        label: "XTSUpdateObjectsRequest"
    },
    {
        value: "XTSDeleteObjectsRequest",
        label: "XTSDeleteObjectsRequest"
    },
    {
        value: "XTSDownloadObjectListRequest",
        label: "XTSDownloadObjectListRequest"
    }
]

export default TestPage