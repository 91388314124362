/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useSearchParams, useBlocker } from 'react-router-dom'

/////////////////////////////////////////////
// Application's

import { newItemValue } from '../../data-exchange/common-use'

/////////////////////////////////////////////
// Object's

import ListPage from './order-list'                         // order
import ViewPage from './order-view'                         // order
import EditPage from './order-edit'                         // order

/////////////////////////////////////////////
// Main component

function OrdersPage({ itemValueInit, choiceItemValue }) {                                // Orders

    useEffect(() => {
        if (!itemValueInit) {
            itemValueInit = newItemValue({ type: 'XTSOrder' })
            console.log('init value')
        }
    }, [])

    const [itemValue, setItemValue] = useState(newItemValue(itemValueInit))

    useEffect(() => {
        setItemValue(itemValueInit)
    }, [itemValueInit])

    // console.log(itemValueInit)

    if (itemValue?.edit) {
        return (
            <div>
                <div>Test</div>
                <EditPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} itemValue={itemValue} />
            </div>
        )
    } else if (itemValue?.id) {
        return (
            <div>
                <div>Test</div>
                <ViewPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} itemValue={itemValue} />
            </div>
        )
    } else {
        return (
            <div>
                <div>Test 4</div>
                <ListPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} />
            </div>
        )
    }

}

/////////////////////////////////////////////
// Export

export default OrdersPage                                   // Orders