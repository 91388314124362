/////////////////////////////////////////////
// Standard's

import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, Card, List } from 'antd'

/////////////////////////////////////////////
// Application's

import { setValue } from '../../data-storage/slice-current'
import { newItemValue } from '../../data-exchange/common-use'

/////////////////////////////////////////////
// Object's


/////////////////////////////////////////////
// Main component

function ProductCard({ item, itemValueInit, setItemValue, choiceItemValue }) {                                       // ProductCard

    // const navigate = useNavigate()
    // // const dispatch = useDispatch()

    // const item = props.item

    // const onClick = (e) => {
    //     console.log('Open item')
    //     navigate('/product/' + item.objectId_id)                    // product
    // }

    const { objectId_id, objectId_dataType, objectId_presentation } = item

    const clickItem = (e) => {
        // const itemValue = { ...itemValueInit, id: objectId_id }
        // console.log('itemValueInit')
        // console.log({ ...itemValueInit, id: objectId_id })
        // console.log(setItemValue)
        setItemValue(newItemValue({ ...itemValueInit, id: objectId_id }))
    }

    const choiceItem = (e) => {
        e.stopPropagation()
        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: objectId_id, presentation: objectId_presentation, dataType: objectId_dataType }
            choiceItemValue(itemValue)
        } else {

        }
    }

    return (
        <Card onClick={clickItem}>
            <div>
                {item.description}
            </div>
            <div>
                Comment: {item.comment}
            </div>
            <div>
                Price: {item.price}
            </div>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export default ProductCard                                          // ProductCard