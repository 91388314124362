import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest, extraData, reducers, setLoading, setSucceeded, setError } from './slice-common'

const initialState = {
    items: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
    tempData: null,  // dữ liệu của các objects được lưu tạm thời, ví dụ khi được tải về bằng XTSGetObjectsRequest
}

export const apiRequest = createAsyncThunk(
    'uomClassifier/apiRequest',
    (data, thunkAPI) => postRequest(data, thunkAPI, sliceUOMClassifier.actions)
)

const sliceUOMClassifier = createSlice({
    name: 'uomClassifier',
    initialState,
    reducers,
    extraReducers(builder) {
        builder
            .addCase(apiRequest.pending, (state) => setLoading(state))
            .addCase(apiRequest.fulfilled, (state, action) => setSucceeded(state))
            .addCase(apiRequest.rejected, (state, action) => setError(state, action))

    },
    extra: extraData,
})

export default sliceUOMClassifier.reducer

export const {
    add,
    update,
    remove,
    load,
    setStatus,
    setTemp
} = sliceUOMClassifier.actions