/////////////////////////////////////////////
// Standard's

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/////////////////////////////////////////////
// Application's

import { postRequest, extraData, setLoading, setSucceeded, setError } from './slice-common'

/////////////////////////////////////////////
// Begin

const initialState = {
    user: null,
    userProfile: null,
    userToken: null,
    acessToken: null,
    deviceId: null,
    telegramId: null,
    zaloId: null,

    tempData: null,  // dữ liệu của các objects được lưu tạm thời
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
}

export const reducers = {

    setParam(state, action) {
        const { key, value } = action.payload
        const keys = [
            'user',
            'userProfile',
            'userToken',
            'accessToken',
            'deviceId',
            'telegramId',
            'zaloId'
        ]
        if (keys.findIndex(key) !== -1) {
            state[key] = value
        }
    },

    signIn(state, action) {
        state.user = action.payload.user
    },

    signOut(state) {
        state.user = null
    },

    setStatus(state, action) {
        state.status = action.payload
    },

    setTemp(state, action) {
        state.tempData = action.payload
    },
}

export const apiRequest = createAsyncThunk(
    'params/apiRequest',                                                           // params         
    (data, thunkAPI) => postRequest(data, thunkAPI, sliceParams.actions)           // sliceParams
)

const sliceParams = createSlice({                                                  // sliceParams
    name: 'params',                                                                // params
    initialState,
    reducers,
    extraReducers(builder) {
        builder
            .addCase(apiRequest.pending, (state) => setLoading(state))
            .addCase(apiRequest.fulfilled, (state) => setSucceeded(state))
            .addCase(apiRequest.rejected, (state, action) => setError(state, action))
    },
    extra: extraData,
})

/////////////////////////////////////////////
// Export's

export default sliceParams.reducer                                                 // sliceParams

export const {
    setParam,
    signIn,
    signOut,
    setStatus,
    setTemp,
} = sliceParams.actions                                                            // sliceParams