
/////////////////////////////////////////////
// Application's

import CustomersPage from '../pages/customer'
import OrdersPage from '../pages/order'
import ProductsPage from '../pages/product'

// function ChoicePage({ itemValueInit, choiceItemValue, pageSettings }) {
function ChoicePage({ itemValueInit, setChoiceOpen, setFieldsValue, pageSettings, }) {

    // console.log('itemValueInit')
    // console.log(itemValueInit)

    // choiceItemValue
    // Hàm sự kiện để xử lý kết quả chọn giá trị ChoicePage vào Attribute của Form
    const choiceItemValue = (itemValue) => {
        console.log('chosenItemValue')
        console.log(itemValue)

        const { itemName } = itemValue
        if (itemName) {
            setFieldsValue({
                [itemName]: itemValue.presentation,
                [itemName + '_id']: itemValue.id,
                [itemName + '_presentation']: itemValue.presentation,
                [itemName + '_type']: itemValue.dataType,
            })
        }
        setChoiceOpen(false)
    }

    const { dataType } = itemValueInit
    const props = { itemValueInit, choiceItemValue, pageSettings }
    const mapPages = {
        XTSCounterparty: <CustomersPage {...props} />,
        XTSOrder: <OrdersPage {...props} />,
        XTSProduct: <ProductsPage {...props} />,
        // Thêm các trang nữa
        // ...
    }

    return mapPages[dataType]
}

export default ChoicePage
