
/////////////////////////////////////////////
// Standard's

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

/////////////////////////////////////////////
// Application's

import { HEADER_BACKGROUND_COLOR, MENU_BACKGROUND_COLOR, HEADER_HEIGHT } from '../commons/constants'
import AppMenu from './menu'

/////////////////////////////////////////////
// Object's


/////////////////////////////////////////////
// Begin

function AppHeader() {

    const [openMenu, setOpenMenu] = useState(false)
    const pageTitle = useSelector(state => state.currentData.pageTitle)

    return (
        <div>
            {/* <div className='WebHeader'></div > */}
            <div className=''>
                <div style={{
                    height: HEADER_HEIGHT,
                    backgroundColor: HEADER_BACKGROUND_COLOR,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    paddingLeft: 15,
                    paddingTop: 0,
                }}>
                    <MenuOutlined
                        className='AppMenuIcon'
                        style={{ fontSize: 30 }}
                        onClick={() => { setOpenMenu(true) }}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        padding: 0,
                    }}>
                        {pageTitle}
                    </div>
                </div>
            </div>
            <Drawer
                placement='left'
                open={openMenu}
                closable={false}
                style={{ backgroundColor: MENU_BACKGROUND_COLOR }}
                onClose={() => { setOpenMenu(false) }}
            >
                <AppMenu
                    isInline={true}
                    closeMenu={() => { setOpenMenu(false) }}
                />
            </Drawer>
        </div>
    )
}

export default AppHeader;