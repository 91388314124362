/////////////////////////////////////////////
// Standard's

import { configureStore } from '@reduxjs/toolkit'

/////////////////////////////////////////////
// Object's

import reducerCurrentData from './slice-current'
import reducerParams from './slice-params'

import reducerProducts from './slice-products'
import reducerOrders from './slice-orders'
import reducerCustomers from './slice-customers'
import reducerUsers from './slice-users'
import reducerUOMClassifier from './slice-uom-classifier'
import reducerMeasurementUnits from './slice-measurement-units'

/////////////////////////////////////////////
// Export's

export default configureStore({
    reducer: {
        params: reducerParams,
        currentData: reducerCurrentData,
        products: reducerProducts,
        orders: reducerOrders,
        customers: reducerCustomers,
        users: reducerUsers,
        uomClassifier: reducerUOMClassifier,
        measurementUnits: reducerMeasurementUnits
    }
})