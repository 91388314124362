
/////////////////////////////////////////////
// Main component

function SettingsPage(props) {
    return (
        <div>
            <p>Settings</p>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default SettingsPage