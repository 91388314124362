/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams, useLocation, useBlocker } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button, Spin } from 'antd'

/////////////////////////////////////////////
// Application's

import ChoicePage from '../../hocs/choice-page'
import { useChoicePage, useOpenPage, useSaveData } from '../../hooks/use-page'
import { newItemValue, xtsProperties, formValuesToItemValue, createRequestDataForSaveFormValues } from '../../data-exchange/common-use'
import { generateUUID } from '../../commons/common-use'
import { createXTSObject } from '../../data-exchange/objects'
import { FormInput } from '../../components/form-items'


/////////////////////////////////////////////
// Object's

import { apiRequest, setStatus, setTemp } from '../../data-storage/slice-customers'        // customers

/////////////////////////////////////////////
// Main component

function CustomerEditPage(props) {                                                           // Customer

    /////////////////////////////////////////////
    // Các useHook chuẩn
    const navigate = useNavigate()
    const dispatch = useDispatch()

    /////////////////////////////////////////////
    // Giải cấu trúc props    
    const { itemValue } = props

    // Biến dùng để quản lý Form
    const [form] = Form.useForm()

    /////////////////////////////////////////////
    // Bắt đầu mở Page

    const dataItem = useSelector(
        state => state.customers.items.find(item => item.objectId_id === itemValue?.id)            // orders
    )

    // console.log('dataItem')
    // console.log(dataItem)

    const { status, tempData } = useSelector(state => state.customers)                             // orders

    const pageTitle = (!dataItem?.objectId_id) && 'Khách hàng mới' || dataItem?.description + ' (soạn)'
    useOpenPage(pageTitle)

    /////////////////////////////////////////////
    // Làm việc với ChoicePage   

    // useEffect(() => {
    //     if (status === 'succeeded' & tempData !== null) {
    //         if (id) navigate(-1)
    //         if (tempData[0].objectId?.id) {
    //             navigate(`${pathname}?id=${tempData[0].objectId?.id}`, { replace: true })
    //         }
    //         dispatch(setStatus('idle'))
    //     }
    // }, [status, tempData, navigate, dispatch])
    // useSaveData({ status, tempData, setStatus, setItemValue: props.setItemValue })

    // Hàm sự kiện khi bấm nút OK trên biểu mẫu Modal ChoicePage
    const handleOk = (value) => {
        setChoiceOpen(false);
    }

    // Hàm sự kiện khi bấm nút Cancel trên biểu mẫu Modal ChoicePage
    const handleCancel = () => {
        setChoiceOpen(false);
    }

    const { choiceOpen, setChoiceOpen, openChoicePage, itemValueInit, setItemValueInit } = useChoicePage()

    /////////////////////////////////////////////
    // Xử lý các sự kiện trên Page   

    // Khi ghi lại dữ liệu xong trên Server thì thay đổi trạng thái status và tempData của state.orders
    // Hàm này gọi useEffect để thực hiện thay đổi trên Page.
    useSaveData({ status, tempData, setStatus, setItemValue: props.setItemValue })

    // finish
    // Hàm sự kiện khi bấm nút Hoàn thành (Ghi lại) trên Form
    const finish = (values => {
        const requestData = createRequestDataForSaveFormValues(values, 'XTSCounterparty')
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    })

    // finishFailed
    // Hàm sự kiện khi bấm nút Cancel trên Form    
    // Quay lại trang trước, hoặc chuyển sang trang View    
    const finishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    // cancel
    // Hàm sự kiện khi bấm nút Cancel trên Form    
    // Quay lại trang trước, hoặc chuyển sang trang View
    const cancel = () => {
        if (props.setItemValue) {
            props.setItemValue(newItemValue({ id: itemValue?.id, edit: false }))
        } else {
            navigate(-1)
        }
    }

    /////////////////////////////////////////
    // Blocker

    // Biến lưu dấu hiệu về việc cấm quay lại trang trước khi bấm nút Back trên Browser
    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            !choiceOpen && currentLocation.pathname !== nextLocation.pathname
    );

    /////////////////////////////////////////
    //

    return (
        <div>
            <p>{pageTitle}</p>
            {
                dataItem && (
                    <div>
                        <p>{dataItem.description}</p>
                        <p>{dataItem.phone}</p>
                    </div>
                )
            }
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <Form
                onFinish={finish}
                onFinishFailed={finishFailed}
            >

                <FormInput
                    name='objectId'
                    dataType='XTSOrder'
                    label='ObjectId'
                    initialValue={formValuesToItemValue(dataItem, 'objectId')}
                    placeholder=''
                    allowClear={true}
                    required={false}
                    setFieldsValue={form.setFieldsValue}
                    openChoicePage={openChoicePage}
                />

                <FormInput
                    name='description'
                    dataType='String'
                    label='Description'
                    defaultValue={dataItem?.description}
                    placeholder='Nhập tên gọi'
                    allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <FormInput
                    name='phone'
                    dataType='String'
                    label='Phone'
                    defaultValue={dataItem?.description}
                    placeholder='Nhập số điện thoại'
                    allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <Form.Item>
                    <Button block type='primary' htmlType='submit' >Ghi lại</Button>
                </Form.Item>
                <Form.Item>
                    <Button block htmlType='button' onClick={cancel}>Hủy bỏ</Button>
                </Form.Item>
            </Form>

        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default CustomerEditPage                                     // Customer