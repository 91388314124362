

/////////////////////////////////////////////
// Main component

function ProductCharacteristicPage(props) {
    return (
        <div>
            <p>Product characteristic page</p>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default ProductCharacteristicPage
