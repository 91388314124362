/////////////////////////////////////////////
// Standard's

import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'antd'

/////////////////////////////////////////////
// Application's

import { useSessionParams } from '../hooks/use-params'

/////////////////////////////////////////////
// Main component

function LogoutPage(props) {

    const { signOut } = useSessionParams()
    const navigate = useNavigate()

    const onFinish = (values) => {
        console.log(values)
        signOut(() => { navigate('/', { replace: true }) })
    }

    const onCancel = (e) => {
        navigate(-1, { replace: true })
    }

    return (
        <div>
            <p>Logout page</p>
            <Form onFinish={onFinish}>
                <Form.Item label='Bạn thực sự muốn Log-out?' />
                <Form.Item>
                    <Button block type='primary' htmlType='submit' >Yes</Button>
                </Form.Item>
                <Form.Item>
                    <Button block htmlType='button' onClick={onCancel}>Cancel</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default LogoutPage