/////////////////////////////////////////////
// Standard's

import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, List, Input, Spin } from 'antd'

/////////////////////////////////////////////
// Application's

import { createXTSObject } from '../../data-exchange/objects'
import { useOpenPage } from '../../hooks/use-page'

/////////////////////////////////////////////
// Object's

import { load, apiRequest, setTemp } from '../../data-storage/slice-products'       // products
import ProductCard from './product-card'                                            // product

/////////////////////////////////////////////
// Main component

function ProductListPage(props) {

    const pageTitle = 'Sản phẩm'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchText, setSearchText] = useState('');
    const { status } = useSelector(state => state.products)                     // products

    const allItems = useSelector(state => state.products.items)                 // products
    const filteredItems = useMemo(() => {
        return allItems.filter(item => {
            const lowerCaseText = searchText.toLowerCase()
            return item.phone?.toLowerCase().includes(lowerCaseText) ||
                item.description?.toLowerCase().includes(lowerCaseText)
        })
    }, [allItems, searchText])


    const loadItems = () => {

        const requestObject = createXTSObject('XTSDownloadObjectListRequest')
        requestObject.dataType = 'XTSProduct'                                   // XTSProduct
        requestObject.prefix = ''

        const url = 'https://jsonplaceholder.typicode.com/posts/1'
        const method = 'GET'
        const headers = {}
        const body = requestObject
        const requestData = {
            method, url, headers, body
        }
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    }

    const clearList = () => {
        dispatch(load())
    }
    const selectOrder = () => {
        // dispatch(load())
    }

    const newItem = () => {
        if (props.setItemValue) {
            props.setItemValue({ edit: true })
        } else {
            // setSearchParams({ edit: true })
        }
    }


    useOpenPage(pageTitle)

    return (
        <div>
            <p>Product List</p>
            <Space>
                <Input.Search
                    placeholder="Tìm theo tên"
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>Count: {filteredItems.length}</div>
            </Space>
            <Space >
                <Form.Item>
                    <Button htmlType='button' onClick={loadItems}>Tải danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={clearList}>Xóa danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={newItem}>Mặt hàng mới</Button>
                </Form.Item>
                {/* <Form.Item>
                    <Button htmlType='button' onClick={selectOrder}>Choice</Button>
                </Form.Item> */}
            </Space>
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={filteredItems}
                renderItem={(item) => (
                    <List.Item>
                        <ProductCard
                            item={item}
                            choiceItemValue={props.choiceItemValue}
                            setItemValue={props.setItemValue}
                            itemValueInit={props.itemValueInit}
                        />
                    </List.Item>
                )} />
        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default ProductListPage