/////////////////////////////////////////////
// Standard's

import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Card, List } from 'antd'

/////////////////////////////////////////////
// Main component

function UOMClassifierCard(props) {

    const navigate = useNavigate()
    const item = props.item
    const onClick = (e) => {
        // navigate('/uom-classifier/' + item.objectId_id)
    }

    return (
        <Card onClick={onClick}>
            <div>
                {item.description}
            </div>
            <div>
                Code: {item.code}
            </div>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export default UOMClassifierCard