/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams, useLocation, useBlocker } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button, Spin, Modal, List, Space } from 'antd'

/////////////////////////////////////////////
// Application's

import ChoicePage from '../../hocs/choice-page'
import { useChoicePage, useOpenPage, useSaveData } from '../../hooks/use-page'
import { newItemValue, xtsProperties, formValuesToItemValue, createRequestDataForSaveFormValues } from '../../data-exchange/common-use'
import { generateUUID } from '../../commons/common-use'
import { createXTSObject } from '../../data-exchange/objects'
import { FormInput } from '../../components/form-items'

/////////////////////////////////////////////
// Object's

import { apiRequest, setStatus, setTemp } from '../../data-storage/slice-orders'                // orders
import { OrderProductRowCard, OrderProductRowPage } from './order-product-row'
import PaymentEditPage from '../payment/payment-edit'

/////////////////////////////////////////////
// Main component

function OrderEditPage(props) {


    /////////////////////////////////////////////
    // Các useHook chuẩn
    const navigate = useNavigate()
    const dispatch = useDispatch()

    /////////////////////////////////////////////
    // Giải cấu trúc props    
    const { itemValue } = props

    // Biến dùng để quản lý Form
    const [form] = Form.useForm()

    /////////////////////////////////////////////
    // Bắt đầu mở Page

    const dataItem = useSelector(
        state => state.orders.items.find(item => item.objectId_id === itemValue?.id)            // orders
    )

    // console.log('dataItem')
    // console.log(dataItem)

    const { status, tempData } = useSelector(state => state.orders)                             // orders

    const pageTitle = (!itemValue?.id) && 'Đơn hàng mới' || `Đơn hàng số ${dataItem?.number} (soạn)`
    useOpenPage(pageTitle)

    /////////////////////////////////////////////
    // Làm việc với phần bảng
    const [valueTables, setValueTables] = useState({})

    useEffect(() => {
        if (dataItem) {
            setFormTables(dataItem, ['products'], valueTables, setValueTables)
            // console.log('dataItem')
            // console.log(dataItem)
        }
    }, [dataItem])


    /////////////////////////////////////////////
    // Làm việc với ChoicePage   

    // // itemValueInit - Để lưu giá trị khi bắt đầu bấm nút mở danh sách chọn giá trị 1 Attribute trên Form
    // // Ví dụ trên form OrderEditPage này có Attribute là Customer, khi bấm nút chọn giá trị 
    // // thì trước tiên sẽ lưu lại các giá trị trong biến này.
    // const [itemValueInit, setItemValueInit] = useState({})

    // // choiceOpen - // State dùng để mở và đóng Modal ChoicePage
    // const [choiceOpen, setChoiceOpen] = useState(false)

    // Hàm sự kiện khi bấm nút OK trên biểu mẫu Modal ChoicePage
    const handleOk = (value) => {
        setChoiceOpen(false);
    }

    // Hàm sự kiện khi bấm nút Cancel trên biểu mẫu Modal ChoicePage
    const handleCancel = () => {
        setChoiceOpen(false)
        setProductRowOpen(false)
    }

    // // openChoicePage
    // // Hàm sự kiện để mở ChoicePage (hiển thị) Modal ChoicePage
    // const openChoicePage = ({ dataType, itemName }) => {
    //     setChoiceOpen(true)                                             // mở Modal
    //     setItemValueInit(newItemValue({ ...itemValueInit, dataType, itemName, id: null }))
    //     // console.log('itemValueInit')
    //     // console.log(itemValueInit)
    // }

    const { choiceOpen, setChoiceOpen, openChoicePage, itemValueInit, setItemValueInit } = useChoicePage()

    /////////////////////////////////////////////
    // Xử lý các sự kiện trên Page   

    // Khi ghi lại dữ liệu xong trên Server thì thay đổi trạng thái status và tempData của state.orders
    // Hàm này gọi useEffect để thực hiện thay đổi trên Page.
    useSaveData({ status, tempData, setStatus, setItemValue: props.setItemValue })

    // finish
    // Hàm sự kiện khi bấm nút Hoàn thành (Ghi lại) trên Form
    const finish = (values => {
        // console.log('valueTables')
        // console.log(valueTables)
        // console.log('values')
        // console.log(values)
        const formValues = getFormValuesWithTables(values, valueTables)
        // console.log('formValues')
        // console.log(formValues)
        const requestData = createRequestDataForSaveFormValues(formValues, 'XTSOrder')
        console.log('requestData')
        console.log(requestData)
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    })

    // finishFailed
    // Hàm sự kiện khi có lỗi trên Form
    const finishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    // cancel
    // Hàm sự kiện khi bấm nút Cancel trên Form    
    // Quay lại trang trước, hoặc chuyển sang trang View
    const cancel = () => {
        if (props.setItemValue) {
            props.setItemValue(newItemValue({ id: itemValue?.id, edit: false }))
        } else {
            navigate(-1)
        }
    }

    /////////////////////////////////////////
    // Order product row page
    const [productRowOpen, setProductRowOpen] = useState()
    const [productRow, setProductRow] = useState({})

    // const newProductRow = () => {
    //     setProductRowOpen(true)
    // }

    const openProductRow = (row) => {
        setProductRowOpen(true)
        setProductRow(row)
    }

    const updateProductRow = (productRow) => {
        setProductRowOpen(false)
        if (productRow) {

        }
        // updateTableRow('products', productRow, setValueTables)
    }


    /////////////////////////////////////////
    // Blocker

    // // Biến lưu dấu hiệu về việc cấm quay lại trang trước khi bấm nút Back trên Browser
    // let blocker = useBlocker(
    //     ({ currentLocation, nextLocation }) =>
    //         !choiceOpen && currentLocation.pathname !== nextLocation.pathname
    // );

    /////////////////////////////////////////
    //

    // console.log('ddataaItem, objectId, customer')
    // console.log(dataItem)
    // console.log(formValuesToItemValue(dataItem, 'objectId'))
    // console.log(formValuesToItemValue(dataItem, 'customer'))

    return (
        <div>
            <p>{pageTitle}</p>
            {
                dataItem && (
                    <div>
                        <p>{dataItem.description}</p>
                        {/* <p>{dataItem.phone}</p> */}
                    </div>
                )
            }
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <Form
                onFinish={finish}
                onFinishFailed={finishFailed}
                form={form}
            >
                <FormInput
                    name='objectId'
                    dataType='XTSOrder'
                    label='ObjectId'
                    initialValue={formValuesToItemValue(dataItem, 'objectId')}
                    placeholder=''
                    allowClear={true}
                    required={false}
                    setFieldsValue={form.setFieldsValue}
                    openChoicePage={openChoicePage}
                />
                <FormInput
                    name='customer'
                    dataType='XTSCounterparty'
                    label='Khách hàng'
                    initialValue={formValuesToItemValue(dataItem, 'customer')}
                    placeholder='Nhập tên gọi'
                    allowClear={true}
                    required={true}
                    setFieldsValue={form.setFieldsValue}
                    openChoicePage={openChoicePage}
                />
                <FormInput
                    name='documentAmount'
                    dataType='Number'
                    label='Số tiền'
                    defaultValue={dataItem?.documentAmount}
                    placeholder='Nhập số tiền'
                    // allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <FormInput
                    name='comment'
                    dataType='String'
                    label='Ghi chú'
                    defaultValue={dataItem?.comment}
                    placeholder='Nhập ghi chú'
                    allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <FormInput
                    name='confirmed'
                    dataType='Boolean'
                    label='Đã xác nhận'
                    defaultChecked={dataItem?.confirmed}
                // placeholder='Đã xác nhận'
                // allowClear={true}
                // required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                {/* <Form.Item label='Phone' name='phone' >
                    <Input placeholder='Nhập số điện thoại' />
                </Form.Item> */}

                <Button block htmlType='button' onClick={() => { openProductRow({}) }}>Dòng mới</Button>

                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 5,
                    }}
                    dataSource={dataItem?.products || []}
                    renderItem={(row) => (
                        <List.Item>
                            <OrderProductRowCard
                                row={row}
                                openProductRow={openProductRow}
                            />
                        </List.Item>
                    )}
                />
                <Space>
                    <Form.Item>
                        <Button block type='primary' htmlType='submit' >Ghi lại</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button block htmlType='button' onClick={cancel}>Hủy bỏ</Button>
                    </Form.Item>
                </Space>
                <Modal
                    title='Chọn phần tử'
                    open={choiceOpen}
                    // onOk={handleOk}
                    // onCancel={handleCancel}
                    footer={[
                        <Button key='back' onClick={handleCancel}>Cancel</Button>,
                    ]}
                >
                    <ChoicePage
                        itemValueInit={itemValueInit}
                        setChoiceOpen={setChoiceOpen}
                        setFieldsValue={form.setFieldsValue}
                    />
                </Modal>
                <Modal
                    title='Soạn dòng hàng hóa'
                    open={productRowOpen}
                    // onOk={handleOk}
                    // onCancel={handleCancel}
                    footer={[
                        // <Button key='back' onClick={handleCancel}>Cancel</Button>,
                    ]}
                >
                    <OrderProductRowPage
                        row={productRow}
                        updateProductRow={updateProductRow}
                    // setCurrentRow={setCurrentProductRow}

                    // itemValueInit={itemValueInit}
                    // setChoiceOpen={setChoiceOpen}
                    // setFieldsValue={form.setFieldsValue}
                    />

                </Modal>
                {/* {blocker.state === 'blocked' ? (
                    <div>
                        <p>Are you sure you want to leave?</p>
                        <button onClick={() => blocker.proceed()}>
                            Proceed
                        </button>
                        <button onClick={() => blocker.reset()}>
                            Cancel
                        </button>
                    </div>
                ) : null} */}
            </Form>

        </div >
    )
}


// function addRow(setState, state, tableName, rowData) {

// }

// function deleteRows(setState, state, tableName, lineNumbers) {

// }

// function updateRow(setState, state, tableName, rowData, lineNumber) {

// }

// function updateRow(setState, state, tableName, rowData, lineNumber) {

// }

function setFormTables(dataItem, tableNames, state, setState) {

    const valueTables = {}
    for (let tableName of tableNames) {
        valueTables[tableName] = dataItem[tableName]
    }
    setState(valueTables)
}

function getFormValuesWithTables(formValues, state) {

    const valueTables = {}
    for (let tableName in state) {
        valueTables[tableName] = state[tableName]
    }
    return { ...formValues, ...valueTables }
}


/////////////////////////////////////////////
// Export's

export default OrderEditPage