/////////////////////////////////////////////
// Standard's

import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
    HomeOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    InfoCircleOutlined,
    ProductOutlined,
    FileTextOutlined,
    TeamOutlined,
    BugOutlined,
    UserOutlined,
    SettingOutlined,
    BlockOutlined,
    CloseOutlined,
} from '@ant-design/icons'

/////////////////////////////////////////////
// Object's

import { MENU_BACKGROUND_COLOR } from '../commons/constants'

const menuItems = [
    { label: "Home", key: "/home", icon: <HomeOutlined /> },
    { label: "Orders", key: "/orders", icon: <FileTextOutlined /> },
    { label: "Products", key: "/products", icon: <ProductOutlined /> },
    { label: "Customers", key: "/customers", icon: <TeamOutlined /> },
    { label: "User profile", key: "/user-profile", icon: < UserOutlined /> },
    { label: "Settings", key: "/settings", icon: < SettingOutlined /> },
    { label: "Test", key: "/test", icon: <BugOutlined /> },
    { label: "Support", key: "/support", icon: <QuestionCircleOutlined /> },
    { label: "About", key: "/about", icon: <InfoCircleOutlined /> },
    { label: "Logout", key: "/logout", icon: <LogoutOutlined />, danger: true }
]

/////////////////////////////////////////////
// Main component

function AppMenu({ isInline = false, closeMenu }) {

    const navigate = useNavigate()
    return (
        <div className='AppMenu'>
            <Menu
                mode={isInline ? 'inline' : 'horizontal'}
                style={{ backgroundColor: MENU_BACKGROUND_COLOR, color: 'white', fontSize: 16, border: 'none' }}
                defaultSelectedKeys={[window.location.pathname]}
                onClick={({ key }) => {
                    navigate(key)
                    console.log(key)
                    if (closeMenu) { closeMenu() }
                }}
                items={menuItems}
            />
        </div>

    )
}

/////////////////////////////////////////////
// Export

export default AppMenu