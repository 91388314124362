/////////////////////////////////////////////
// Standard's

// import { useEffect, useState } from 'react'
// import { useParams, useNavigate, useSearchParams, useLocation, useBlocker } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button, Spin, Modal, List, Card, Space } from 'antd'

/////////////////////////////////////////////
// Application's

import { FormInput } from '../../components/form-items'
import { useChoicePage } from '../../hooks/use-page'
import { formValuesToItemValue } from '../../data-exchange/common-use'
import ChoicePage from '../../hocs/choice-page'
import { useEffect } from 'react'


/////////////////////////////////////////////
// Object's



/////////////////////////////////////////////
// Main components

function OrderProductRowCard(props) {

    const row = props.row

    const openRow = () => {
        // props.setProductRowOpen(true)
        props.openProductRow(row)
    }

    return (
        <Card onClick={openRow}>
            <div>
                #{row?.lineNumber}
            </div>
            <div>
                Product: {row?.product}
            </div>
            <div>
                Quantity: {row?.quantity}
            </div>
            {/* <Button block htmlType='button' onClick={openRow}>Soạn dòng</Button> */}
        </Card>
    )
}

function OrderProductRowPage(props) {

    const row = props.row

    useEffect(() => {
        form.setFieldsValue(row)
    }, [row])
    // console.log('row')
    // console.log(row)

    const [form] = Form.useForm()
    const { choiceOpen, setChoiceOpen, openChoicePage, itemValueInit, setItemValueInit } = useChoicePage()

    const cancel = () => {
        props.updateProductRow()
    }

    const OK = () => {
        // row = row
        props.updateProductRow(row)
    }

    // Hàm sự kiện khi bấm nút OK trên biểu mẫu Modal ChoicePage
    const handleOk = (value) => {
        setChoiceOpen(false);
    }

    // Hàm sự kiện khi bấm nút Cancel trên biểu mẫu Modal ChoicePage
    const handleCancel = () => {
        setChoiceOpen(false)
        // setProductRowOpen(false)

    }

    return (
        <Card>
            <div>
                Dòng: {row?.lineNumber}
            </div>
            <div>
                Product: {row?.product}
            </div>
            <div>
                Quantity: {row?.quantity}
            </div>
            <Form
                // onFinish={finish}
                // onFinishFailed={finishFailed}
                form={form}
            >
                <FormInput
                    name='product'
                    dataType='XTSProduct'
                    label='Sản phẩm'
                    // initialValue={formValuesToItemValue((row) || {}, 'product')}
                    placeholder='Nhập sản phẩm'
                    allowClear={true}
                    required={true}
                    setFieldsValue={form.setFieldsValue}
                    openChoicePage={openChoicePage}
                />
                <FormInput
                    name='quantity'
                    dataType='Number'
                    label='Số lượng'
                    // defaultValue={row?.quantity}
                    placeholder='Nhập số lượng'
                    // allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <FormInput
                    name='price'
                    dataType='Number'
                    label='Đơn giá'
                    // defaultValue={row?.price}
                    placeholder='Nhập đơn giá'
                    // allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <FormInput
                    name='total'
                    dataType='Number'
                    label='Thành tiền'
                    // defaultValue={row?.total}
                    placeholder='Số tiền'
                    // allowClear={true}
                    required={true}
                // setFieldsValue={form.setFieldsValue}
                // openChoicePage={openChoicePage}
                />
                <Space>
                    <Button block htmlType='button' onClick={OK}>Đồng ý</Button>
                    <Button block htmlType='button' onClick={cancel}>Hủy bỏ</Button>
                </Space>
            </Form>
            <Modal
                title='Chọn phần tử'
                open={choiceOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel}>Cancel</Button>,
                ]}
            >
                <ChoicePage
                    itemValueInit={itemValueInit}
                    setChoiceOpen={setChoiceOpen}
                    setFieldsValue={form.setFieldsValue}
                />
            </Modal>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export { OrderProductRowCard, OrderProductRowPage }