
/////////////////////////////////////////////
// Main component

function SupportPage(props) {
    return (
        <div>
            <p>Support page</p>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default SupportPage