
import { objectsDescription, objectsEnums } from '../data-exchange/objects-description'

function createDataObject(dataTypeName) {

}

function createXTSObject(xtsTypeName, attributeNames = null) {

    const newObject = { _type: xtsTypeName }

    const fields = []
    const objectDescription = objectsDescription[xtsTypeName]

    if (!objectDescription) {
        return undefined
    }

    // console.log("objectsDescription:")
    // console.log(objectsDescription)

    if (attributeNames != null) {

        attributeNames.For((item) => {
            fields.push(item.Lower())
        })
    }
    const getAllFields = (fields.length === 0)
    // console.log("getAllFields:")
    // console.log(getAllFields)

    for (const key in objectDescription) {
        const property = objectDescription[key]
        // console.log("property")
        // console.log(property)
        if (!getAllFields && !fields.find((item) => { return item === property.name })) {
            continue
        } else if (key === '_type') {
            continue
        }

        if (property.count === -1) {
            newObject[key] = []
        } else if (property.type === 'String') {
            newObject[key] = property?.ref || ''
        } else if (property.type === 'Boolean') {
            newObject[key] = property?.ref || false
        } else if (property.type === 'Number') {
            newObject[key] = property?.ref || 0
        } else if (property.type === 'Date') {
            newObject[key] = property?.ref || '0001-01-01'
        } else if (property.type === 'DateTime') {
            newObject[key] = property?.ref || '0001-01-01T00:00:00'
        } else if (property.type.startsWith('XTS')) {
            const xtsValue = createXTSObject(property.type)
            // console.log('newObject[key] ')
            // console.log(xtsValue)
            if (property.ref && property.ref !== '') {
                xtsValue.dataType = property.ref
                // console.log("xtsValue.dataType")
                // console.log(xtsValue.dataType)
            }
            newObject[key] = xtsValue
            // console.log(key)
            // console.log(newObject)
        } else {
            newObject[key] = null
        }
    }

    // if (newObject.hasOwnProperty('objectId')) {
    //     newObject.objectId = { ...newObject.objectId, dataType: xtsTypeName }
    //     console.log('_type')
    // console.log(xtsTypeName)
    // console.log(newObject)
    // }

    return newObject

}

function validateXTSType(xtsObject, xtsTypeName) {

}

function fillXTSPropertyByDataAttribute(xtsObject, xtsPropertyName, dataAttribute, XTSProperty) {

}

function fillDataAttributeByXTSProperty(dataAttribute, xtsObject, xtsPropertyName, XTSProperty) {

}

function getTypeNameByObjectId(xtsObjectId) {

}

function getDataObject(xtsObjectId) {

}

export { createDataObject, createXTSObject }