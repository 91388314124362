/////////////////////////////////////////////
// Standard's

import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, List, Input, Spin } from 'antd'

/////////////////////////////////////////////
// Application's

import { createXTSObject } from '../../data-exchange/objects'
import { useOpenPage } from '../../hooks/use-page'

/////////////////////////////////////////////
// Object's

import { load, apiRequest, setTemp } from '../../data-storage/slice-orders'         // orders
import OrderCard from './order-card'                                                // order

/////////////////////////////////////////////
// Main component

function OrderListPage(props) {

    const pageTitle = 'Danh sách đơn hàng'

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [searchParams, setSearchParams] = useSearchParams()
    const [searchText, setSearchText] = useState('');

    const { status } = useSelector(state => state.orders)                     // orders
    const allItems = useSelector(state => state.orders.items)                 // orders
    const filteredItems = useMemo(() => {
        return allItems.filter(item => {
            const lowerCaseText = searchText.toLowerCase()
            return item.number?.toLowerCase().includes(lowerCaseText) ||
                item.customer?.toLowerCase().includes(lowerCaseText)
        })
    }, [allItems, searchText])

    const loadItems = () => {

        const requestObject = createXTSObject('XTSDownloadObjectListRequest')
        requestObject.dataType = 'XTSOrder'                                   // XTSOrder
        requestObject.prefix = ''

        const url = 'https://jsonplaceholder.typicode.com/posts/1'
        const method = 'GET'
        const headers = {}
        const body = requestObject
        const requestData = {
            method, url, headers, body
        }
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    }

    const clearList = () => {
        dispatch(load())
    }

    const newItem = () => {
        if (props.setItemValue) {
            props.setItemValue({ edit: true })
        } else {
            // setSearchParams({ edit: true })
        }
    }

    useOpenPage(pageTitle)

    return (
        <div>
            <p>Order List</p>
            <Space>
                <Input.Search
                    placeholder="Tìm theo tên"
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>Count: {filteredItems.length}</div>
            </Space>
            <Space >
                <Form.Item>
                    <Button htmlType='button' onClick={loadItems}>Tải danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={clearList}>Xóa danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={newItem}>Đơn hàng mới</Button>
                </Form.Item>
                {/* <Form.Item>
                    <Button htmlType='button' onClick={choiceItem}>Choice order</Button>
                </Form.Item> */}
            </Space>
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={filteredItems}
                renderItem={(item) => (
                    <List.Item>
                        <OrderCard
                            item={item}
                            choiceItemValue={props.choiceItemValue}
                            setItemValue={props.setItemValue}
                            itemValueInit={props.itemValueInit}
                        />
                    </List.Item>
                )} />
        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default OrderListPage