/////////////////////////////////////////////
// Standard's

import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Space, List, Input, Spin } from 'antd'

/////////////////////////////////////////////
// Application's

import { createXTSObject } from '../data-exchange/objects'

/////////////////////////////////////////////
// Object's

import { load, apiRequest, setTemp } from '../data-storage/slice-uom-classifier'
import UOMClassifierCard from '../pages/uom-classifier-card'

/////////////////////////////////////////////
// Main component

function UOMClassifierListPage() {

    const [searchText, setSearchText] = useState('');

    const allItems = useSelector(state => state.uomClassifier?.items) || []
    const filteredItems = useMemo(() => {
        return allItems.filter(item => {
            const lowerCaseText = searchText.toLowerCase()
            return item?.code?.toLowerCase().includes(lowerCaseText) ||
                item?.description?.toLowerCase().includes(lowerCaseText)
        })
    }, [allItems, searchText])

    const dispatch = useDispatch()
    // const navigate = useNavigate()

    const loadItems = () => {

        const requestObject = createXTSObject('XTSDownloadObjectListRequest')
        requestObject.dataType = 'XTSUOMClassifier'
        requestObject.prefix = ''
        const requestObjectJSON = JSON.stringify(requestObject, null, 2)

        const url = 'https://jsonplaceholder.typicode.com/posts/1'
        const method = 'GET'
        const headers = {}
        const body = requestObject
        const requestData = {
            method, url, headers, body
        }
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    }

    const clearList = () => {
        dispatch(load())
    }

    const { status = '' } = useSelector(state => state?.uomClassifier || {})

    return (
        <div>
            <p>Units of measurement classifier</p>
            <Space>
                <Input.Search
                    placeholder="Tìm theo tên"
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>Count: {filteredItems.length}</div>
            </Space>
            <Space >
                <Form.Item>
                    <Button htmlType='button' onClick={loadItems}>Tải danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={clearList}>Xóa danh sách</Button>
                </Form.Item>
            </Space>
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={filteredItems}
                renderItem={(item) => (
                    <List.Item>
                        <UOMClassifierCard item={item} />
                    </List.Item>
                )} />
        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default UOMClassifierListPage
