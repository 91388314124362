/////////////////////////////////////////////
// Standard's

import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, Card, List } from 'antd'

/////////////////////////////////////////////
// Application's

import { setValue } from '../../data-storage/slice-current'
import { newItemValue } from '../../data-exchange/common-use'

/////////////////////////////////////////////
// Main component

function CustomerCard({ item, itemValueInit, setItemValue, choiceItemValue }) {

    // const navigate = useNavigate()
    // const dispatch = useDispatch()
    // const [searchParams, setSearchParams] = useSearchParams()

    // console.log('CustomerCard.choiceItemValue')
    // console.log(choiceItemValue)

    const { objectId_id, objectId_dataType, objectId_presentation } = item
    // console.log('Card.objectId_id')
    // console.log(objectId_id)

    const clickItem = (e) => {
        // const itemValue = { ...itemValueInit, id: objectId_id }
        // console.log('itemValueInit')
        // console.log({ ...itemValueInit, id: objectId_id })
        // console.log(setItemValue)
        setItemValue(newItemValue({ ...itemValueInit, id: objectId_id }))
    }

    const choiceItem = (e) => {
        e.stopPropagation()
        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: objectId_id, presentation: objectId_presentation, dataType: objectId_dataType }
            choiceItemValue(itemValue)
        } else {

        }
    }

    return (
        <Card onClick={clickItem}>
            <div>
                {item.description}
            </div>
            <div>
                Code: {item.code}
            </div>
            <div>
                Phone: {item.phone}
            </div>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export default CustomerCard