/////////////////////////////////////////////
// Standard's


/////////////////////////////////////////////
// Application's

import { generateUUID } from '../commons/common-use'
import { xtsObjectToFormValues } from '../data-exchange/common-use'

// Test +
import dataList from '../data-mock/'
// Test -

/////////////////////////////////////////////
// Begin

export const extraData = {

}

export async function postRequest(requestData, thunkAPI, sliceActions) {

    const { rejectWithValue, dispatch } = thunkAPI
    const { url, headers, body, method = 'POST' } = requestData
    // const method = requestData?.method || 'POST'   //  const method = data.method ?? 'POST'
    const requestObject = body ?? {}              // Test

    // console.log('requestObject 1')
    // console.log(requestObject)

    try {
        if (headers & body & url) {
            throw new Error('Error: no url/headers/body in parameter Data!')
        }

        // console.log('headers')
        // console.log(headers)
        // headers['Content-Type'] = 'application/json'
        const fetchInit = { method, headers }
        if (method !== 'GET') {
            if (headers.hasOwnProperty('Content-Type')) {
                if (headers['Content-Type'] === 'application/json') {
                    fetchInit = { ...fetchInit, body: JSON.stringify(body) }
                } else {
                    fetchInit = { ...fetchInit, body: body }
                }
            }
        }

        const response = await fetch(url, fetchInit)
        if (!response.ok) {
            throw new Error('Server error!')
        }

        const responseData = await response.json()

        if (responseData['_type'] === 'XTSGetObjectListResponse') {
            const { items } = responseData
            dispatch(sliceActions.setTemp(items))
        } else if (responseData['_type'] === 'XTSGetObjectsResponse') {
            const { objects } = responseData
            dispatch(sliceActions.setTemp(objects))
        } else if (responseData['_type'] === 'XTSCreateObjectsResponse' ||
            requestObject['_type'] === 'XTSCreateObjectsRequest') {
            var objects = responseData?.objects
            // Test+
            if (requestObject['_type'] === 'XTSCreateObjectsRequest') {
                objects = requestObject.objects
                const object = objects[0]
                object.objectId.id = generateUUID()
                object.objectId.presentation = object.objectId.id
            }
            // Test-                
            dispatch(sliceActions.add(objects))
        } else if (responseData['_type'] === 'XTSUpdateObjectsResponse' ||
            requestObject['_type'] === 'XTSUpdateObjectsRequest') {
            var objects = responseData?.objects
            // Test+
            if (requestObject['_type'] === 'XTSUpdateObjectsRequest') {
                objects = requestObject.objects
            }
            // Test-                
            dispatch(sliceActions.update(objects))
        } else if (responseData['_type'] === 'XTSDeleteObjectsResponse') {
            const { objectIds } = responseData
            dispatch(sliceActions.remove(objectIds))
        } else if (responseData['_type'] === 'XTSDownloadObjectListRequest' ||
            requestObject['_type'] === 'XTSDownloadObjectListRequest') {
            var objectList = responseData?.objects
            // Test+
            if (requestObject['_type'] === 'XTSDownloadObjectListRequest') {
                objectList = dataList(requestObject['dataType'])
                // console.log('objectList')
                // console.log(objectList)
            }
            // Test-
            // console.log('objectList')
            // console.log(objectList)
            dispatch(sliceActions.load(objectList))
        } else {
            throw new Error('Error: incorrect ResponseObject!')
        }

    } catch (error) {
        // console.log(error)
        return rejectWithValue(error.message)
    }
}

/////////////////////////////////////////
// default reducers

export const reducers = {
    add(state, action) {
        state.tempData = action.payload
        addItems(state.items, action.payload)
    },

    update(state, action) {
        state.tempData = action.payload
        updateItems(state.items, action.payload)
    },

    remove(state, action) {
        state.tempData = null
        removeItems(state.items, action.payload)
    },

    setTemp(state, action) {
        state.tempData = action.payload
    },

    load(state, action) {
        state.tempData = null
        loadItems(state.items, action.payload)
    },

    setStatus(state, action) {
        state.status = action.payload
    },
}

/////////////////////////////////////////
// default extraReducers

export const setLoading = (state) => {
    state.status = 'loading';
    state.error = null
}

export const setSucceeded = (state) => {
    state.status = 'succeeded';
}

export const setError = (state, action) => {
    state.status = 'failed';
    state.error = action.payload;
}

/////////////////////////////////////////
// addItems
function addItem(stateItems, xtsObject) {
    // console.log('xtsObject:')
    // console.log(xtsObject)
    const formObject = xtsObjectToFormValues(xtsObject)
    stateItems.push(formObject)

}

function addItems(stateItems, xtsObjects) {
    // console.log('xtsObjects:')
    // console.log(xtsObjects)
    for (let xtsObject of xtsObjects) {
        addItem(stateItems, xtsObject)
    }
}

/////////////////////////////////////////
// updateItems
function updateItem(stateItems, xtsObject) {
    const { objectId } = xtsObject
    const index = stateItems.findIndex(item => item.objectId_id === objectId?.id)

    if (index !== -1) {
        stateItems[index] = xtsObjectToFormValues(xtsObject)
    }
}

function updateItems(stateItems, xtsObjects) {
    for (let xtsObject of xtsObjects) {
        updateItem(stateItems, xtsObject)
    }
}

/////////////////////////////////////////
// removeItems
function removeItem(stateItems, xtsObjectId) {
    const { id } = xtsObjectId
    if (xtsObjectId) {
        stateItems = stateItems.filter(item => item.objectId_id !== id)
    }
}

function removeItems(stateItems, xtsObjectIds) {
    stateItems = stateItems.filter(
        item => (xtsObjectIds.findIndex(xtsObjectId => (item?.objectId_id === xtsObjectId.id)) === -1)
    )
}

/////////////////////////////////////////
// loadItems --> clear
function loadItems(stateItems, xtsObjects = null) {
    stateItems.splice(0, stateItems.length)
    if (xtsObjects) {
        addItems(stateItems, xtsObjects)
    }
}

/////////////////////////////////////////////
// Export's

export {
    addItems,
    updateItems,
    removeItems,
    loadItems,
}