/////////////////////////////////////////////
// Standard's

import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, List } from 'antd'

/////////////////////////////////////////////
// Application's

import { useOpenPage } from '../../hooks/use-page'

/////////////////////////////////////////////
// Object's

import ProductUOMCard from '../product-uom-card'

/////////////////////////////////////////////
// Main component

function ProductViewPage({ itemValue, itemValueInit, setItemValue, choiceItemValue }) {

    const navigate = useNavigate()
    // const dispatch = useDispatch()

    // const { id } = useParams()

    const dataItem = useSelector(state =>
        state.products.items.find(item => item.objectId_id === itemValue?.id)                 // products
    )
    const pageTitle = dataItem.description
    useOpenPage(pageTitle)

    const editItem = (e) => {
        // console.log('newItemValue')
        // console.log(newItemValue({ ...itemValue, edit: true, id: dataItem?.objectId_id }))

        setItemValue({ ...itemValueInit, edit: true, id: dataItem?.objectId_id })     // Chuyển sang thẻ Soạn
    }

    const stepBack = (e) => {
        if (setItemValue) {
            setItemValue({ ...itemValueInit, edit: false, id: null })     // Chuyển sang thẻ Danh sách
        } else {
            navigate(-1)
        }
    }

    const choiceItem = (e) => {
        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: dataItem.objectId_id, presentation: dataItem.objectId_presentation }
            choiceItemValue(itemValue)
        }
    }

    return (
        <div>
            <p>Product</p>
            {
                dataItem && (
                    < div >
                        <p>{dataItem?.description}</p>
                        <p>Comment: {dataItem?.comment}</p>
                        <p>Price: {dataItem?.price}</p>
                    </div>
                )
            }
            <List
                grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 2,
                    md: 4,
                    lg: 6,
                    xl: 8,
                    xxl: 10,
                }}
                dataSource={dataItem?.uoms || []}
                renderItem={(item) => (
                    <List.Item>
                        <ProductUOMCard item={item} />
                    </List.Item>
                )}
            />

            <Button htmlType='button' onClick={stepBack}>Quay lại</Button>
            <Button htmlType='button' onClick={editItem}>Soạn mặt hàng</Button>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>

        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default ProductViewPage