/////////////////////////////////////////////
// Standard's

import React, { useRef, useState } from 'react'
import { Form, Input, InputNumber, Button, Spin, Modal, Flex, Switch } from 'antd'
import { PicCenterOutlined } from '@ant-design/icons'

/////////////////////////////////////////////
// Application's

function FormInput(props) {

    const { dataType } = props

    switch (dataType) {
        case 'String':
            return <FormTextEdit {...props} />
        case 'Number':
            return <FormNumberEdit {...props} />
        case 'Date':
            return <FormDateEdit {...props} />
        case 'DateTime':
            return <FormDateTimeEdit {...props} />
        case 'Time':
            return <FormTimeEdit {...props} />
        case 'Boolean':
            return <FormChecker {...props} />
        case '':
        case undefined:
        case null:
            return <></>
        default:
            return <FormValueChoice {...props} />
    }
}

function FormValueChoice(props) {

    const {
        name,
        dataType = '',
        placeholder,
        required,
        initialValue,
        allowClear,
        setFieldsValue,
        openChoicePage,
        inputProps = {},
    } = props

    const _itemProps = { ...props }
    delete _itemProps.dataType
    delete _itemProps.allowClear
    delete _itemProps.setFieldsValue
    delete _itemProps.openChoicePage

    const _inputProps = { ...inputProps, placeholder, required }
    const nameDataType = name + '_dataType'
    const nameId = name + '_id'
    const namePresentation = name + '_presentation'

    const addonAfter = <>
        <PicCenterOutlined onClick={() => { startChoiceItemValue() }} />
    </>

    const setItemValue = ({ id, presentation }) => {
        const newValue = {
            [nameId]: id,
            [namePresentation]: presentation
        }
        setFieldsValue(newValue)
    }

    const changeItemValue = (e) => {
        const currentValue = e.currentTarget.value
        if (currentValue && currentValue !== '') {
            openChoicePage({ dataType: dataType, itemName: name })
        }
    }

    const startChoiceItemValue = (e) => {
        openChoicePage({ dataType: dataType, itemName: name })
        // inputRef.current.blur()
    }

    const inputRef = useRef(null)

    // Debug+
    const showHidenItems = false
    const hidenItems = (!showHidenItems) && { display: 'none' } || {}
    // Debug-

    return (
        <div>
            <Form.Item {..._itemProps} initialValue={initialValue?.presentation}  >
                <Input {
                    ..._inputProps}
                    addonAfter={addonAfter}
                    allowClear={allowClear}
                    onClear={() => setItemValue({})}
                    onChange={(e) => { changeItemValue(e) }}
                    autoComplete='off'
                // ref={inputRef}
                />
            </Form.Item>
            <Form.Item
                name={nameDataType}
                label={nameDataType}
                initialValue={dataType}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={nameId}
                label={nameId}
                initialValue={initialValue?.id}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={namePresentation}
                label={namePresentation}
                initialValue={initialValue?.presentation}
                style={hidenItems} >
                <Input />
            </Form.Item>
        </div >
    )
}

function FormTextEdit(props) {

    const {
        name,
        // dataType = 'String',
        placeholder,
        required,
        defaultValue,
        allowClear,
        // setFieldsValue,
        // openChoicePage,
        inputProps = {},
    } = props

    const _itemProps = { ...props }
    delete _itemProps.dataType
    delete _itemProps.allowClear
    delete _itemProps.defaultValue
    // delete _itemProps.openChoicePage

    const _inputProps = { ...inputProps, placeholder, required }
    // const nameDataType = name + '_dataType'
    // const nameId = name + '_id'
    // const namePresentation = name + '_presentation'

    const addonAfter = <>
        {/* <PicCenterOutlined onClick={() => { startChoiceItemValue() }} /> */}
    </>

    // const setItemValue = ({ id, presentation }) => {
    //     const newValue = {
    //         [nameId]: id,
    //         [namePresentation]: presentation
    //     }
    //     setFieldsValue(newValue)
    // }

    // const changeItemValue = (e) => {
    //     const currentValue = e.currentTarget.value
    //     if (currentValue && currentValue !== '') {
    //         openChoicePage({ dataType: dataType, itemName: name })
    //     }
    // }

    // const startChoiceItemValue = (e) => {
    //     openChoicePage({ dataType: dataType, itemName: name })
    //     // inputRef.current.blur()
    // }

    // const inputRef = useRef(null)

    // Debug+
    // const showHidenItems = false
    // const hidenItems = (!showHidenItems) && { display: 'none' } || {}
    // Debug-

    return (
        <div>
            <Form.Item {..._itemProps} initialValue={defaultValue} >
                <Input {
                    ..._inputProps}
                    // addonAfter={addonAfter}
                    allowClear={allowClear}
                    // onClear={() => setItemValue({})}
                    // onChange={(e) => { changeItemValue(e) }}
                    autoComplete='off'
                // ref={inputRef}
                />
            </Form.Item>
            {/* <Form.Item
                name={nameDataType}
                label={nameDataType}
                initialValue={dataType}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={nameId}
                label={nameId}
                initialValue={initialValue?.id}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={namePresentation}
                label={namePresentation}
                initialValue={initialValue?.presentation}
                style={hidenItems} >
                <Input />
            </Form.Item> */}
        </div >
    )
}

function FormNumberEdit(props) {

    const {
        name,
        // dataType = 'String',
        placeholder,
        required,
        defaultValue,
        // allowClear,
        // setFieldsValue,
        // openChoicePage,
        inputProps = {},
    } = props

    const _itemProps = { ...props }
    delete _itemProps.dataType
    delete _itemProps.defaultValue
    // delete _itemProps.openChoicePage

    const _inputProps = { ...inputProps, placeholder, required }
    // const nameDataType = name + '_dataType'
    // const nameId = name + '_id'
    // const namePresentation = name + '_presentation'

    const addonAfter = <>
        {/* <PicCenterOutlined onClick={() => { startChoiceItemValue() }} /> */}
    </>

    // const setItemValue = ({ id, presentation }) => {
    //     const newValue = {
    //         [nameId]: id,
    //         [namePresentation]: presentation
    //     }
    //     setFieldsValue(newValue)
    // }

    // const changeItemValue = (e) => {
    //     const currentValue = e.currentTarget.value
    //     if (currentValue && currentValue !== '') {
    //         openChoicePage({ dataType: dataType, itemName: name })
    //     }
    // }

    // const startChoiceItemValue = (e) => {
    //     openChoicePage({ dataType: dataType, itemName: name })
    //     // inputRef.current.blur()
    // }

    // const inputRef = useRef(null)

    // Debug+
    // const showHidenItems = false
    // const hidenItems = (!showHidenItems) && { display: 'none' } || {}
    // Debug-

    return (
        <div>
            <Form.Item {..._itemProps} initialValue={defaultValue} >
                <InputNumber
                    {..._inputProps}
                    // addonAfter={addonAfter}
                    // defaultValue={defaultValue}
                    // onClear={() => setItemValue({})}
                    // onChange={(e) => { changeItemValue(e) }}
                    autoComplete='off'
                // ref={inputRef}
                />
            </Form.Item>
            {/* <Form.Item
                name={nameDataType}
                label={nameDataType}
                initialValue={dataType}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={nameId}
                label={nameId}
                initialValue={initialValue?.id}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={namePresentation}
                label={namePresentation}
                initialValue={initialValue?.presentation}
                style={hidenItems} >
                <Input />
            </Form.Item> */}
        </div >
    )
}

function FormChecker(props) {

    const {
        name,
        // dataType = 'String',
        // placeholder,
        // required,
        defaultChecked,
        // allowClear,
        // setFieldsValue,
        // openChoicePage,
        inputProps = {},
    } = props

    const _itemProps = { ...props }
    delete _itemProps.dataType
    delete _itemProps.defaultChecked
    // delete _itemProps.openChoicePage

    const _inputProps = { ...inputProps, defaultChecked }
    // const nameDataType = name + '_dataType'
    // const nameId = name + '_id'
    // const namePresentation = name + '_presentation'

    const addonAfter = <>
        {/* <PicCenterOutlined onClick={() => { startChoiceItemValue() }} /> */}
    </>

    // const setItemValue = ({ id, presentation }) => {
    //     const newValue = {
    //         [nameId]: id,
    //         [namePresentation]: presentation
    //     }
    //     setFieldsValue(newValue)
    // }

    // const changeItemValue = (e) => {
    //     const currentValue = e.currentTarget.value
    //     if (currentValue && currentValue !== '') {
    //         openChoicePage({ dataType: dataType, itemName: name })
    //     }
    // }

    // const startChoiceItemValue = (e) => {
    //     openChoicePage({ dataType: dataType, itemName: name })
    //     // inputRef.current.blur()
    // }

    // const inputRef = useRef(null)

    // Debug+
    // const showHidenItems = false
    // const hidenItems = (!showHidenItems) && { display: 'none' } || {}
    // Debug-

    return (
        <div>
            <Form.Item {..._itemProps}  >
                <Switch  {
                    ..._inputProps}
                // addonAfter={addonAfter}
                // defaultChecked={defaultChecked}
                // onClear={() => setItemValue({})}
                // onChange={(e) => { changeItemValue(e) }}
                // autoComplete='off'
                // ref={inputRef}
                />
            </Form.Item>
            {/* <Form.Item
                name={nameDataType}
                label={nameDataType}
                initialValue={dataType}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={nameId}
                label={nameId}
                initialValue={initialValue?.id}
                style={hidenItems} >
                <Input />
            </Form.Item>
            <Form.Item
                name={namePresentation}
                label={namePresentation}
                initialValue={initialValue?.presentation}
                style={hidenItems} >
                <Input />
            </Form.Item> */}
        </div >
    )
}

function FormDateEdit(props) {


}

function FormDateTimeEdit(props) {


}


function FormTimeEdit(props) {


}





export { FormInput }
