/////////////////////////////////////////////
// Application's

import { createXTSObject } from "./objects"
import { formatDateTime, generateUUID } from "../commons/common-use"
import { objectsDescription } from "./objects-description"

/////////////////////////////////////////////
// Begin

function xtsValueToFormValue(xtsValue, property = undefined) {

    var result = xtsValue

    const isDate = (string) => {
        const regexDate = /^\d{4}-\d{2}-\d{2}$/
        const regexDateTime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/

        return regexDate.test(string) || regexDateTime.test(string)
    }

    // if (typeof xtsValue === 'number') {
    //     result = xtsValue
    // } else if (typeof value === 'boolean') {
    //     result = xtsValue
    // } else if (typeof value === 'string') {
    //     if (property) {
    //         if (property.type === 'Date' || property.type === 'DateTime') {
    //             result = new Date(xtsValue)
    //         } else {
    //             result = xtsValue
    //         }
    //     } else if (isDate(xtsValue)) {
    //         result = newDate(xtsValue)
    //     } else {
    //         result = xtsValue
    //     }
    // } else if (value === null) {
    //     result = xtsValue
    // } else if (typeof value === 'undefined') {
    //     result = xtsValue
    // } else if (Array.isArray(value)) {
    //     result = xtsValue
    // } else if (typeof value === 'object') {
    //     if (value.hasOwnProperty('id')) {
    //         result = value['presentation']
    //     } else {
    //         result = xtsValue
    //     }
    // } else {
    //     result = xtsValue
    // }

    if (typeof xtsValue === 'string') {
        if (property) {
            if (property.type === 'Date' || property.type === 'DateTime') {
                result = xtsValue   // ?
            } else {
                result = xtsValue
            }
        } else if (isDate(xtsValue)) {
            // result = new Date(xtsValue)
            result = xtsValue   //
        } else {
            result = xtsValue
        }
    } else if (Array.isArray(xtsValue)) {
        result = []
    } else if (typeof xtsValue === 'object') {
        if (xtsValue.hasOwnProperty('id')) {
            result = xtsValue['presentation']
        }
    } else if (property?.count === -1) {
        result = []
    }

    return result
}

function xtsObjectToFormValues(xtsObject) {

    try {

        const formValues = {}
        if (!xtsObject.hasOwnProperty('_type')) {
            return formValues
        }

        const properties = xtsProperties(xtsObject['_type'])

        for (let key in xtsObject) {
            const xtsValue = xtsObject[key]
            const property = properties ? properties[key] : undefined
            var formValue = xtsValueToFormValue(xtsValue, property)
            if (xtsValue) {
                if (xtsValue.hasOwnProperty('id')) {
                    formValues[key + '_id'] = xtsValue['id']
                    formValues[key + '_type'] = xtsValue['_type']
                    formValues[key + '_dataType'] = xtsValue['dataType']
                    formValues[key + '_presentation'] = xtsValue['presentation']
                    // formValues[key] = xtsValue['presentation']
                } else if (Array.isArray(xtsValue)) {
                    // console.log('xtsValue')
                    // console.log(xtsValue)
                    for (const row of xtsValue) {
                        const rowValue = xtsObjectToFormValues(row)
                        formValue.push(rowValue)
                    }
                }
            }
            formValues[key] = formValue
        }
        // console.log(formValues)

        // console.log('formValues')
        // console.log(formValues)

        return formValues
    } catch (error) {
        console.log(error.message)
    }

}

// Lấy giá trị kiểu XTS từ formValues.
// Có kiểm tra kiểm dữ liệu đầy đủ hơn so với phương án xtsValue
function formValuesToXTSValue(formValues, property, defaultValue = undefined) {

    var xtsValue
    const propertyName = property.name
    const formValue = formValues[propertyName]

    if (property.count === -1) {
        // Đây là phần bảng
        xtsValue = []
        if (formValue && Array.isArray(formValue)) {
            for (let rowValue of formValue) {
                console.log('property.type')
                console.log(property.type)
                console.log(rowValue)
                const xtsRow = formValuesToXTSObject(rowValue, property.type)
                xtsValue.push(xtsRow)
            }
        }
    } else {
        // Đây là thuộc tính


        // console.log(formValues)
        // console.log(formValue)

        // console.log('property')
        // console.log(propertyName)
        // console.log(formValue)

        // if (formValue) {
        // const typeOfFormValue = typeof formValue
        switch (property.type) {
            // case 'String':
            //     xtsValue = (typeOfFormValue === 'string') && formValue
            //     break
            // case 'Number':
            //     xtsValue = (typeOfFormValue === 'string' || typeOfFormValue === 'number') && Number(formValue)
            //     break
            // case 'Boolean':
            //     xtsValue = (typeOfFormValue === 'string' || typeOfFormValue === 'boolean') && Boolean(formValue)
            //     break
            // case 'Date':
            // case 'DateTime':
            //     if (formValue instanceof Date) {
            //         xtsValue = formatDateTime(formValue)
            //     } else if (typeOfFormValue === 'string') {
            //         xtsValue = formValue        //new Date(formValue)
            //     }
            //     break
            case 'String':
            case 'Number':
            case 'Boolean':
                xtsValue = formValue
            case 'Date':
            case 'DateTime':
                // console.log('xtsValue')
                // console.log(xtsValue)
                // console.log(property)
                break
            case 'XTSObjectId':
                xtsValue = (defaultValue) || createXTSObject('XTSObjectId')
                const propertyName_dataType = propertyName + '_dataType'
                const propertyName_presentation = propertyName + '_presentation'
                const propertyName_id = propertyName + '_id'
                if (formValues.hasOwnProperty(propertyName_presentation)) {
                    xtsValue.presentation = formValues[propertyName_presentation]
                }
                if (formValues.hasOwnProperty(propertyName_id)) {
                    xtsValue.id = formValues[propertyName_id]
                }
                if (formValues.hasOwnProperty(propertyName_dataType)) {
                    xtsValue.dataType = formValues[propertyName_dataType]
                }
                break
            default:
            // Không có gì

        }
    }
    // }
    return xtsValue
}

function formValuesToXTSObject(formValues, dataType) {

    // const { _type } = properties
    const properties = xtsProperties(dataType)
    const xtsObject = createXTSObject(dataType)


    if (!xtsObject) { return xtsObject }

    // console.log('properties')
    // console.log(properties)
    // console.log('xtsObject 1')
    // console.log(xtsObject)

    for (let key in properties) {
        if (key === '_type') {
            continue
        }

        const property = properties[key]
        // const xtsValue = formValuesToXTSValue(formValues, property, xtsObject[key])
        // console.log('xtkeysObject')
        xtsObject[key] = formValuesToXTSValue(formValues, property, xtsObject[key])

        // console.log(key)
        // console.log(xtsObject)

        //     if (xtsValue) {
        //         xtsObject[key] = xtsValue
        //     }
    }
    // console.log(xtsObject)

    // console.log('xtsObject 2')
    // console.log(xtsObject)

    return xtsObject
}

// Để tạo giá trị mặc định cho itemValueInit
function formValuesToItemValue(formValues, itemName) {

    var xtsValue
    // const xtsValue = defaultValue
    xtsValue = {}
    if (formValues) {
        const id = formValues[itemName + '_id']
        // const type = formValues[itemName + '_type']
        const dataType = formValues[itemName + '_dataType']
        const presentation = formValues[itemName + '_presentation']
        if (id && dataType) {
            // xtsValue._type = defaultValue._type
            // xtsValue.dataType = 
            // xtsValue.presentation = presentation
            // xtsValue = { id, dataType, presentation, itemName, _type: 'objectId' }
            if (!xtsValue.dataType || xtsValue.dataType === '') {
                // xtsValue.dataType = defaultValue.dataType
            }
            // xtsValue = { id, dataType, presentation, itemName, _type: 'objectId' }
        } else {
        }
        xtsValue = { id, dataType, presentation, itemName, _type: 'objectId' }
    }

    return xtsValue

    // console.log('formValuesToItemValue 2')
    // console.log(dataType)
    // console.log(id)

}

function xtsProperties(xtsTypeName) {
    return objectsDescription[xtsTypeName]
}

function newItemValue(itemValue) {
    const result = {
        itemName: null,
        edit: null,
        id: null,
        dataType: null,
        presentation: null,
        multiSelection: false
    }
    return { ...result, ...itemValue }
}

function createRequestDataForSaveFormValues(formValues, dataType, objectPresentation) {

    const newObject = formValuesToXTSObject(formValues, dataType)

    const requestName = (formValues?.objectId_id) && 'XTSUpdateObjectsRequest' || 'XTSCreateObjectsRequest'
    const requestObject = createXTSObject(requestName)

    // console.log('newObject')
    // console.log(newObject)


    requestObject.objects = [newObject]

    const url = 'https://jsonplaceholder.typicode.com/posts/1'
    const method = 'GET'
    const headers = {}
    const body = requestObject
    const requestData = { method, url, headers, body }

    // console.log('requestObject')
    // console.log(formValues)
    // console.log(requestObject)

    return requestData
}

/////////////////////////////////////////////
// Export's

export {
    xtsValueToFormValue,
    xtsObjectToFormValues,
    formValuesToItemValue,
    formValuesToXTSValue,
    formValuesToXTSObject,
    xtsProperties,
    newItemValue,
    createRequestDataForSaveFormValues,
}