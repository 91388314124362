
import { createContext, useState } from "react";

export const ParamsContext = createContext(null)

export const ParamsProvider = ({ children }) => {

    const initParams = {
        userName: null,
        userToken: null,
        deviceId: null,
        telegramId: null,
        fullName: null,
        phone: null,
        email: null,
        lastUpdate: null,
    }

    const [params, setParams] = useState(initParams)

    const signIn = (newUser, callback) => {
        setValue('userName', newUser)
        callback()
    }
    const signOut = (callback) => {
        setValue('userName')
        callback()
    }
    const setValue = (key, value = null) => {
        const newParams = {}
        newParams[key] = value
        setParams({ ...params, ...newParams })
    }
    const setValues = (newParams) => {
        setParams({ ...params, ...newParams })
    }
    const getValue = (key) => {
        return params[key]
    }
    const clearValues = () => {
        setParams(initParams)
    }

    const value = {
        params,
        signIn,
        signOut,
        setValue,
        getValue,
        setValues,
        clearValues
    }

    return <ParamsContext.Provider value={value}>
        {children}
    </ParamsContext.Provider>
}