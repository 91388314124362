/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useSearchParams, useBlocker } from 'react-router-dom'

/////////////////////////////////////////////
// Application's

import { newItemValue } from '../../data-exchange/common-use'

/////////////////////////////////////////////
// Object's

import ListPage from './product-list'                      // product
import ViewPage from './product-view'                      // product
import EditPage from './product-edit'                      // product

/////////////////////////////////////////////
// Main component

function ProductsPage({ itemValueInit, choiceItemValue }) {                             // Customers

    useEffect(() => {
        if (!itemValueInit) {
            itemValueInit = newItemValue({ type: 'XTSProduct' })    // Product
            console.log('init value')
        }
    }, [])

    const [itemValue, setItemValue] = useState(newItemValue(itemValueInit))
    // const { selectionMode, choiceItemValue } = props

    useEffect(() => {
        setItemValue(itemValueInit)
    }, [itemValueInit])

    // const _setItemValue = (itemValue) => {
    //     setItemValue(itemValue)
    // }

    // console.log('CustomersPage.itemValueInit')
    // console.log(itemValueInit)

    // console.log('CustomersPage.itemValue')
    // console.log(itemValue)

    // useEffect(() => {
    //     setItemValue(props.itemValue)
    // }, [props.itemValue])

    // const childProps = {
    //     ...props,
    //     itemvalue: props.itemValue,
    //     choiceItemValue: props.choiceItemValue,
    //     setItemValue
    // }

    if (itemValue?.edit) {
        return (
            <div>
                <div>Test</div>
                <EditPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} itemValue={itemValue} />
            </div>
        )
    } else if (itemValue?.id) {
        return (
            <div>
                <div>Test</div>
                <ViewPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} itemValue={itemValue} />
            </div>
        )
    } else {
        return (
            <div>
                <div>Test</div>
                <ListPage setItemValue={setItemValue} choiceItemValue={choiceItemValue} itemValueInit={itemValueInit} />
            </div>
        )
    }
}

/////////////////////////////////////////////
// Export

export default ProductsPage                                // Products