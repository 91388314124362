/////////////////////////////////////////////
// Standard's

import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, Card, List } from 'antd'

/////////////////////////////////////////////
// Application's

import { setValue } from '../../data-storage/slice-current'
import { newItemValue } from '../../data-exchange/common-use'

/////////////////////////////////////////////
// Main component

function OrderCard({ item, itemValueInit, setItemValue, choiceItemValue }) {

    // const navigate = useNavigate()
    // const dispatch = useDispatch()
    // const [searchParams, setSearchParams] = useSearchParams()

    // const { item, selectionMode, onClick } = props

    const { objectId_id, objectId_dataType, objectId_presentation } = item



    const clickItem = (e) => {
        if (setItemValue) {
            setItemValue(newItemValue({ ...itemValueInit, id: objectId_id }))
            // console.log('OrderCard.clickItem')
            // console.log(newItemValue({ ...itemValueInit, id: objectId_id }))
        } else {

        }
    }

    const choiceItem = (e) => {
        e.stopPropagation()
        // console.log('OrderCard.choiceItemValue')

        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: objectId_id, presentation: objectId_presentation, dataType: objectId_dataType }
            choiceItemValue(itemValue)
        }
    }

    return (
        <Card onClick={clickItem}>
            <div>
                Số: {item.number} ngày: {item.date}
            </div>
            <div>
                Khách hàng: {item.customer}
            </div>
            <div>
                Số tiền: {item.documentAmount}
            </div>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export default OrderCard