import React from 'react'
import { FOOTER_BACKGROUND_COLOR, FOOTER_HEIGHT } from '../commons/constants'

function AppFooter() {
    return (
        <footer className='App-Footer' style={{
            height: FOOTER_HEIGHT,
            backgroundColor: FOOTER_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold'
        }}>
            XTS 2024
        </footer >
    )
}

export default AppFooter