/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'


/////////////////////////////////////////////
// Application's

import { setPageTitle } from '../data-storage/slice-current'
import { newItemValue } from '../data-exchange/common-use'

/////////////////////////////////////////////
// Object's


/////////////////////////////////////////////
// useCustomPageHooks

// Thực hiện các thao tác ban đầu khi bắt đầu mở trang
// Tham số:
//      pageTitle: tiêu đề trang
//
function useOpenPage(pageTitle) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle(pageTitle))
    }, [navigate])

}

// // Thực hiện các thao tác khi lưu lại trang ==> Chuyển đến trang Xem
// Tham số:
//      status: tiêu đề trang
//      tempData
//      setStatus
//      setItemValue
function useSaveData({ status, tempData, setStatus, setItemValue }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const { pathname } = useLocation()

    useEffect(() => {
        if (status === 'succeeded' & tempData !== null) {
            // if (id) navigate(-1)                 // Nếu như trong trang hiện tại mà id chưa điền thì nghĩa là Item mới
            if ((setItemValue) && tempData[0].objectId?.id) {         // id chính thức được lưu là nằm trong tempData
                // navigate(`${pathname}?id=${tempData[0].objectId?.id}`, { replace: true })
                setItemValue(newItemValue({ id: tempData[0].objectId?.id, edit: false }))
            }
            dispatch(setStatus('idle'))
        }
    }, [status, tempData, navigate, dispatch])

}

function useChoicePage() {

    // itemValueInit - Để lưu giá trị khi bắt đầu bấm nút mở danh sách chọn giá trị 1 Attribute trên Form
    // Ví dụ trên form OrderEditPage này có Attribute là Customer, khi bấm nút chọn giá trị 
    // thì trước tiên sẽ lưu lại các giá trị trong biến này.
    const [itemValueInit, setItemValueInit] = useState({})

    // choiceOpen - // State dùng để mở và đóng Modal ChoicePage
    const [choiceOpen, setChoiceOpen] = useState(false)

    // openChoicePage
    // Hàm sự kiện để mở ChoicePage (hiển thị) Modal ChoicePage
    const openChoicePage = ({ dataType, itemName }) => {
        setChoiceOpen(true)         // mở Modal
        setItemValueInit(newItemValue({ ...itemValueInit, dataType, itemName, id: null }))
    }

    return { choiceOpen, setChoiceOpen, openChoicePage, itemValueInit, setItemValueInit }
}


/////////////////////////////////////////////
// Export's

export {
    useOpenPage,
    useSaveData,
    useChoicePage
}