/////////////////////////////////////////////
// Standard's

import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'

/////////////////////////////////////////////
// Application's

import { useOpenPage } from '../../hooks/use-page'

/////////////////////////////////////////////
// Main component

function CustomerViewPage({ itemValue, itemValueInit, setItemValue, choiceItemValue }) {

    const navigate = useNavigate()
    // const dispatch = useDispatch()
    // const [searchParams, setSearchParams] = useSearchParams()

    // const [itemValue, setItemValue] = useState(newItemValue({ id: props.id, edit: props.edit }))
    // const itemValue = itemValueInit

    // var itemId
    // if (props.setValues) {
    //     itemId = props.id
    // } else {
    //     const queryOptions = Object.fromEntries(searchParams)
    //     itemId = queryOptions.id
    // }
    // console.log('CustomerViewPage.itemValueInit')
    // console.log(itemValueInit)

    const dataItem = useSelector(state =>
        state.customers.items.find(item => item.objectId_id === itemValue?.id)                 // customers
    )
    // console.log('dataItem')
    // console.log(dataItem)
    const pageTitle = (dataItem?.description) || ''
    useOpenPage(pageTitle)

    const editItem = (e) => {
        // console.log('newItemValue')
        // console.log(newItemValue({ ...itemValue, edit: true, id: dataItem?.objectId_id }))

        setItemValue({ ...itemValueInit, edit: true, id: dataItem?.objectId_id })     // Chuyển sang thẻ Soạn
    }

    const stepBack = (e) => {
        if (setItemValue) {
            setItemValue({ ...itemValueInit, edit: false, id: null })     // Chuyển sang thẻ Danh sách
        } else {
            navigate(-1)
        }
    }

    const choiceItem = (e) => {
        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: dataItem.objectId_id, presentation: dataItem.objectId_presentation }
            choiceItemValue(itemValue)
        }
    }

    return (
        <div>
            <p>Customer</p>
            {
                dataItem && (
                    <div>
                        <p>{dataItem?.description}</p>
                        <p>{dataItem?.phone}</p>
                    </div>
                )
            }

            <Button htmlType='button' onClick={stepBack}>Quay lại</Button>
            <Button htmlType='button' onClick={editItem}>Soạn khách hàng</Button>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>

        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default CustomerViewPage