
/////////////////////////////////////////////
// Main component

function LandingPage(props) {

    return (
        <div>
            <p>Landing page</p>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default LandingPage