
const MENU_BACKGROUND_COLOR = 'darkorange'
const HEADER_BACKGROUND_COLOR = 'blue'
const FOOTER_BACKGROUND_COLOR = 'lightgray'
const HEADER_HEIGHT = '48px'
const FOOTER_HEIGHT = '40px'

export {
    MENU_BACKGROUND_COLOR,
    HEADER_BACKGROUND_COLOR,
    FOOTER_BACKGROUND_COLOR,
    HEADER_HEIGHT,
    FOOTER_HEIGHT
}
