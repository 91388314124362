/////////////////////////////////////////////
// Standard's

import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Card, List } from 'antd'

/////////////////////////////////////////////
// Main component

function ProductUOMCard(props) {                                       // ProductUOMCard

    const item = props.item

    return (
        <Card>
            <div>
                #{item.lineNumber}
            </div>
            <div>
                UOM: {item.objectId_presentation}
            </div>
            <div>
                coefficient: {item.coefficient}
            </div>
        </Card>
    )
}

/////////////////////////////////////////////
// Export's

export default ProductUOMCard                                          // ProductUOMCard