// import logo from './logo.svg';

import './App.css';
import { React, useState } from 'react';
import { Layout } from 'antd';

import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';


import AppHeader from './components/header';
import AppFooter from './components/footer';
import AppSider from './components/sider';
import AppRoutes, { RouteItems } from './components/routes'

import { HEADER_HEIGHT, FOOTER_HEIGHT, MENU_BACKGROUND_COLOR } from './commons/constants'

import CheckAuth from './hocs/auth-checking'
import { ParamsProvider } from './hocs/params-provider'

function App() {

  // const { Header, Sider, Content, Footer } = Layout

  // return (
  //   <div style={{ height: '100vh', backgroundColor: 'rgb(0, 150, 255)' }}>
  //     <Layout>
  //       <Sider trigger={null} breakpoint='sm' collapsedWidth='0' style={{ height: '100vh', motionDurationMid: '0.5s', backgroundColor: MENU_BACKGROUND_COLOR }}><AppSider /></Sider>
  //       <Layout style={{ height: '100vh' }}>
  //         <Header style={{ height: HEADER_HEIGHT, padding: 0 }}><AppHeader /></Header>
  //         <Content style={{ overflowY: 'auto' }}><AppRoutes /></Content>
  //         <Footer style={{ height: FOOTER_HEIGHT, padding: 0 }}><AppFooter /></Footer>
  //       </Layout>
  //     </Layout>
  //   </div>
  // )


  const router = createBrowserRouter(RouteItems())

  return (
    <div style={{ height: '100vh', backgroundColor: 'rgb(0, 150, 255)' }}>
      <ParamsProvider>
        <RouterProvider router={router} />
      </ParamsProvider>
    </div >
  )

}

export default App;
