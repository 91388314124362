/////////////////////////////////////////////
// Standard's

import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Button, Space, List, Input, Spin } from 'antd'

/////////////////////////////////////////////
// Application's

import { createXTSObject } from '../../data-exchange/objects'
import { useOpenPage } from '../../hooks/use-page'

/////////////////////////////////////////////
// Object's

import { load, apiRequest, setTemp } from '../../data-storage/slice-customers'             // customers
import CustomerCard from './customer-card'                                       // customer

/////////////////////////////////////////////
// Main component

function CustomerListPage(props) {                                                      // Customer

    // console.log('CustomerList.props')
    // console.log(props)

    const pageTitle = 'Danh sách khách hàng'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const [searchParams, setSearchParams] = useSearchParams()
    const [searchText, setSearchText] = useState('')
    const { status } = useSelector(state => state.customers)                            // customers

    const allItems = useSelector(state => state.customers.items)                        // customers
    const filteredItems = useMemo(() => {
        return allItems.filter(item => {
            const lowerCaseText = searchText.toLowerCase()
            return item.phone?.toLowerCase().includes(lowerCaseText) ||
                item.description?.toLowerCase().includes(lowerCaseText)
        })
    }, [allItems, searchText])

    const loadItems = () => {

        const requestObject = createXTSObject('XTSDownloadObjectListRequest')
        requestObject.dataType = 'XTSCounterparty'
        requestObject.prefix = ''

        const url = 'https://jsonplaceholder.typicode.com/posts/1'
        const method = 'GET'
        const headers = {}
        const body = requestObject
        const requestData = {
            method, url, headers, body
        }
        dispatch(setTemp(null))
        dispatch(apiRequest(requestData))
    }

    const clearList = () => {
        dispatch(load())
    }

    const newItem = () => {
        if (props.setItemValue) {
            props.setItemValue({ edit: true })
        } else {
            // setSearchParams({ edit: true })
        }
    }

    useOpenPage(pageTitle)

    // const { setItemValue, choiceItemValue } = props


    // const choiceItem = () => {
    //     if (props.setValues) {
    //         props.choiceItems({ item: true })
    //     } else {
    //         setSearchParams({ edit: true })
    //     }

    //     // dispatch(load())
    // }


    return (
        <div>
            <p>Customer List</p>
            <Space>
                <Input.Search
                    placeholder="Tìm theo tên"
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>Count: {filteredItems.length}</div>
            </Space>
            <Space >
                <Form.Item>
                    <Button htmlType='button' onClick={loadItems}>Tải danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={clearList}>Xóa danh sách</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='button' onClick={newItem}>Khách hàng mới</Button>
                </Form.Item>
                {/* <Form.Item>
                    <Button htmlType='button' onClick={choiceItem}>Choice customer</Button>
                </Form.Item> */}
            </Space>
            <Spin spinning={status === 'loading'} fullscreen={true} />
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={filteredItems}
                renderItem={(item) => (
                    <List.Item>
                        <CustomerCard
                            item={item}
                            choiceItemValue={props.choiceItemValue}
                            setItemValue={props.setItemValue}
                            itemValueInit={props.itemValueInit}
                        />
                    </List.Item>
                )} />
        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default CustomerListPage                                                         // Customer