/////////////////////////////////////////////
// Standard's

import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal } from 'antd'

/////////////////////////////////////////////
// Application's

import { useOpenPage } from '../../hooks/use-page'
import PaymentEditPage from '../payment/payment-edit'

/////////////////////////////////////////////
// Main component

function OrderPage({ itemValue, itemValueInit, setItemValue, choiceItemValue }) {

    const navigate = useNavigate()
    // const dispatch = useDispatch()
    // const [searchParams, setSearchParams] = useSearchParams()
    // const { id } = Object.fromEntries(searchParams)

    const dataItem = useSelector(state =>
        state.orders.items.find(item => item.objectId_id === itemValue?.id)                 // orders
    )

    const pageTitle = `Đơn hàng số ${dataItem?.number}`
    useOpenPage(pageTitle)

    const editItem = (e) => {
        setItemValue({ ...itemValueInit, edit: true, id: dataItem?.objectId_id })     // Chuyển sang thẻ Soạn
    }

    const stepBack = (e) => {
        if (setItemValue) {
            setItemValue({ ...itemValueInit, edit: false, id: null })     // Chuyển sang thẻ Danh sách
        } else {
            navigate(-1)
        }
    }

    const choiceItem = (e) => {
        // console.log('choiceItem')
        if (choiceItemValue) {
            const itemValue = { ...itemValueInit, id: dataItem.objectId_id }
            choiceItemValue(itemValue)
        }
    }

    /////////////////////////////////////////
    // Payment

    const [paymentOpen, setPaymentOpen] = useState()

    const openPayment = () => {
        setPaymentOpen(true)
    }

    const updatePayment = (dataItem) => {
        setPaymentOpen(false)
    }

    return (
        <div>
            <p>Order</p>
            < div >
                <p>Số: {dataItem?.number}</p>
                <p>Ngày: {dataItem?.date}</p>
                <p>Khách hàng: {dataItem?.customer}</p>
                <p>Số tiền: {dataItem?.documentAmount}</p>
                <p>Ghi chú: {dataItem?.comment}</p>
                <p>Đã xác nhận: {String(dataItem?.confirmed)}</p>
            </div>

            <Modal
                title='Thanh toán'
                open={paymentOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={[
                    // <Button key='back' onClick={handleCancel}>Cancel</Button>,
                ]}
            >
                <PaymentEditPage
                    row={dataItem}
                    updatePayment={updatePayment}
                // setCurrentRow={setCurrentProductRow}

                // itemValueInit={itemValueInit}
                // setChoiceOpen={setChoiceOpen}
                // setFieldsValue={form.setFieldsValue}
                />

            </Modal>

            <Button htmlType='button' onClick={stepBack}>Quay lại</Button>
            <Button htmlType='button' onClick={choiceItem}>Chọn</Button>
            <Button htmlType='button' onClick={editItem}>Soạn đơn hàng</Button>
            <Button htmlType='button' onClick={openPayment}>Thanh toán</Button>

        </div >
    )
}

/////////////////////////////////////////////
// Export's

export default OrderPage