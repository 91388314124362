/////////////////////////////////////////////
// Standard's

import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Input, Button } from 'antd'

/////////////////////////////////////////////
// Application's

import { useSessionParams } from '../hooks/use-params'

/////////////////////////////////////////////
// Main component

function LoginPage() {

    const navigate = useNavigate()
    const location = useLocation()
    const { signIn } = useSessionParams()

    const { path } = location.state

    // const fromPage = location.state?.from?.pathname || '/home'

    const onFinish = (values => {
        const userName = values.userName
        const password = values.password
        // console.log(userName)
        // console.log(fromPage)
        signIn(userName, () => navigate(path, { replace: true }))
    })

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div>
            <p>Login page</p>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <Form.Item label='User name' name='userName' initialValue='a'>
                    <Input placeholder='Input user name' required={false} />
                </Form.Item>
                <Form.Item label='Password' name='password'>
                    <Input placeholder='Password' />
                </Form.Item>
                <Form.Item>
                    <Button block type='primary' htmlType='submit' >Login</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

/////////////////////////////////////////////
// Export's

export default LoginPage